module.exports = {
    logistics_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getOpenRentals: {
            query: "SELECT rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sQuoteType='Rentacomputer.com' "
                + "AND rcq.dtDeliveryDate < DATEADD(month,1,GETDATE()) "
                + "AND rcq.dtPickupDate > DATEADD(month,-2,GETDATE()) "
                + "AND rcq.sStatus = 'Closed' "
                + "ORDER BY rcq.dtDeliveryDate asc",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Gets all open rentals.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        trackingCompleteStatus: {
            query: "UPDATE PACKAGES SET bCompleted=[trackingStatus] WHERE id=[trackingID]",
            req: ["trackingStatus", "trackingID"],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Updates package completion status",
            tables: ["PACKAGES"],
            type: "UPDATE",
            whereUsed: [],
        },
        lateDeliveries: {
            query: "SELECT rcq.id, rcq.dtDeliveryDate, rcq.sCompany, rcq.sDeliveryTracking, "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND (p.sTrackingNumber='EX' OR p.sTrackingNumber='REP')) AS nExceptions "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.id NOT IN (1795753,1797623,1797387,1798203,1795998,1799047,1797892,1798807) "
                + "AND ((rcq.nDeliveryType=2 AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= '6/25/2019 00:00' AND ((rcq.dtDeliveryDate + '23:59' < GetDate() AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 AND p.sStatusCode <> 'DE') > 0) "
                + "OR (SELECT COUNT(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND p.nType=1 AND p.dtEstimatedDelivery >= rcq.dtDeliveryDate+1 + '04:01' AND p.sStatusCode <> 'DE') > 0) "
                + "AND (SELECT COUNT(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND p.sTrackingNumber='Cancelled')=0 "
                + "AND (SELECT Count(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND (p.sTrackingNumber='rem')) = 0 "
                + "AND (SELECT Count(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND (p.sTrackingNumber='FREIGHT'))=0)) ORDER BY rcq.dtDeliveryDate ",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "sCompany", label: "Customer Company" },
                { key: "dtDeliveryDate", label: "Quote Delivery Date" },
                { key: "nExceptions", label: "Number of Exceptions" },
                { key: "sDeliveryTracking", label: "Delivery Tracking Number" },
            ],
            desc: "Shows all orders where tracking is not scheduled for delivery in time to make the delivery date on the order. "
                + "For customer-caused delivery exceptions, put 'EX' in tracking to remove red status. "
                + "For non-customer caused delays, red note the rep, and put 'REP' in tracking to remove red status. "
                + "To remove quotes from this list put 'REM' in tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        deliveryTracking: {
            query: "SELECT p.* "
                + "FROM PACKAGES p "
                + "WHERE p.nQuote IN "
                + "(SELECT rcq.id FROM rac_customer_quotes rcq WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL "
                + "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com') ",
            req: [],
            fields: [

            ],
            desc: "Lists all packages from CAM Open Installs",
            tables: ["PACKAGES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
            ],

        },
        returnReminders: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nRemindedOfReturn IS NULL AND rcq.nParentQuote IS NULL AND "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nParentQuote = rcq.id AND rcq2.dtPickupDate=rcq.dtPickupDate "
                + "AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0) = 0 "
                + "AND (SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nParentQuote = rcq.id AND rcq2.dtQuoteDate>=rcq.dtPickupDate "
                + "AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0) = 0 "
                + "AND (SELECT COUNT(p.id) FROM PACKAGES p WHERE p.sStatusCode NOT IN ('IT', 'DE') AND p.nQuote=rcq.id AND p.nType=2) > 0 "
                + "AND rcq.dtPickupDate >= DATEADD(day,1,GETDATE()) AND rcq.dtPickupDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id ",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
            ],
            desc: "List of quotes that are due to be returned tomorrow but the return tracking isn't active yet. "
                + "Remind the customer of their return the day before it is due. "
                + "Don't forget to remind the vendor on vendor deliveries.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        vendorPreDelivery: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed < GETDATE() AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType = 1 AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote = rcq.id AND p.sTrackingNumber='VPD') = 0 "
                + "AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,1,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) AND rcq.nContractType <> 3 ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "List of quotes that are due to be delivered tomorrow but haven't been confirmed with the vendor yet. "
                + "Confirm the details of all vendor delivery orders with the vendor, the day before delivery. "
                + "Put 'VPD' in tracking to remove them from this list.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        customerPreDelivery: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed < GETDATE() AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType = 1 AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.sTrackingNumber='CPD') = 0 "
                + "AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,1,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) AND rcq.nContractType <> 3 ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "List of quotes that are due to be delivered tomorrow but haven't been confirmed with the customer yet. "
                + "After the vendor confirms the details, remind the customer of their delivery. "
                + "Put 'CPD' in tracking to remove them from this list. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        addLateCallNote: {
            query: "INSERT INTO RAC_QUOTE_NOTES ("
                + "nQuote, sNote, nRep, nType, dtTimeStamp, nCategory"
                + ") VALUES ("
                + "[quoteNum], '[noteText]', [repID], 1, GETDATE(), 11)",
            req: ["quoteNum", "noteText", "repID",],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Adds a note to the system with category Logistics - Late Return Call.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "INSERT",
            whereUsed: [],
        },
        ordersNotYetReturnedv2: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate, rcq.sCompany, "
                + "rcq.sName, rcq.sPhone, rcq.sEmail, rcq.sState, rcq.sDName, rcq.sDPhone, rcq.sDState, "
                + "(SELECT COUNT(rqn.id) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS nLateCalls, "
                + "(SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS dtLastCall, "
                + "(SELECT TOP 1 sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11 ORDER BY rqn.dtTimeStamp desc) AS sLastNote "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.dtPickupDate > DATEADD(month,-1,GETDATE()) AND rcq.nDeliveryType IN (0,2) AND rcq.sStatus='Closed' AND rcq.dtPickupDate <= GETDATE() "
                + "AND ("
                + "SELECT COUNT(rcq2.id) "
                + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                + "WHERE rcq2.nParentQuote=rcq.id AND rcq2.dtPickupDate>=rcq.dtPickupDate AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0"
                + ") = 0 "
                + "AND ("
                + "SELECT COUNT(p.id) "
                + "FROM PACKAGES p "
                + "WHERE p.sStatusCode NOT IN ('IT','DE') AND NOT (p.sStatusCode='AC' AND p.sCarrierCode='FedEx') "
                + "AND p.nQuote=rcq.id AND p.nType=2 AND (p.bCompleted=0 OR p.bCompleted IS NULL) "
                + ") > 0 "
                + "AND (NOT (SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) < DATEADD(day,-1,GETDATE()) OR (SELECT COUNT(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) = 0) "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "sCompany", label: "Company Name" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
                { key: "sCompany", label: "Customer Name" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Contact Phone" },
                { key: "sEmail", label: "Contact Email" },
                { key: "sState", label: "Contact State" },
                { key: "sDName", label: "Delivery Contact Name" },
                { key: "sDPhone", label: "Delivery Contact Phone" },
                { key: "sDState", label: "Delivery Contact State" },
                { key: "nLateCalls", label: "Late Call Notes" },
                { key: "dtLastCall", label: "Date of Most Recent Last Call" },
                { key: "sLastNote", label: "Text of Most Recent Last Call Note" },
            ],
            desc: "Orders show up here if tracking hasn't been activated by the return date. "
                + "Send reminders to customers who are due to ship back today, and call them. "
                + "Make a note of type Logistics - Late Return Call. "
                + "Repeat this process the next business day. "
                + "If the customer has not returned equipment the business day after the second call, notify the rep to create a rebill. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES", "RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        ordersNotYetReturned: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate, rcq.sCompany, "
                + "rcq.sName, rcq.sPhone, rcq.sEmail, rcq.sState, rcq.sDName, rcq.sDPhone, rcq.sDState, "
                + "(SELECT COUNT(rqn.id) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS nLateCalls, "
                + "(SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS dtLastCall, "
                + "(SELECT TOP 1 sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11 ORDER BY rqn.dtTimeStamp desc) AS sLastNote "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.dtPickupDate > DATEADD(month,-1,GETDATE()) AND rcq.nDeliveryType IN (0,2) AND rcq.sStatus='Closed' AND rcq.dtPickupDate <= GETDATE() "
                + "AND ("
                + "SELECT COUNT(rcq2.id) "
                + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                + "WHERE rcq2.nParentQuote=rcq.id AND rcq2.dtPickupDate>=rcq.dtPickupDate AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0"
                + ") = 0 "
                + "AND ("
                + "SELECT COUNT(p.id) "
                + "FROM PACKAGES p "
                + "WHERE p.sStatusCode NOT IN ('IT','DE') AND NOT (p.sStatusCode='AC' AND p.sCarrierCode='FedEx') "
                + "AND p.nQuote=rcq.id AND p.nType=2 AND (p.bCompleted=0 OR p.bCompleted IS NULL) "
                + ") > 0 "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "sCompany", label: "Company Name" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
                { key: "sCompany", label: "Customer Name" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Contact Phone" },
                { key: "sEmail", label: "Contact Email" },
                { key: "sState", label: "Contact State" },
                { key: "sDName", label: "Delivery Contact Name" },
                { key: "sDPhone", label: "Delivery Contact Phone" },
                { key: "sDState", label: "Delivery Contact State" },
                { key: "nLateCalls", label: "Late Call Notes" },
                { key: "dtLastCall", label: "Date of Most Recent Last Call" },
                { key: "sLastNote", label: "Text of Most Recent Last Call Note" },
            ],
            desc: "Orders show up here if tracking hasn't been activated by the return date. "
                + "Send reminders to customers who are due to ship back today, and call them. "
                + "Make a note of type Logistics - Late Return Call. "
                + "Repeat this process the next business day. "
                + "If the customer has not returned equipment the business day after the second call, notify the rep to create a rebill. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES", "RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        packagesMissingPO: {
            query: "SELECT p.nQuote FROM PACKAGES p WHERE p.sTrackingNumber LIKE '%[0-9]%' AND (p.bCompleted=0 OR p.bCompleted IS NULL) AND p.npo is null AND p.dtLastUpdate >= '1/1/2019'",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote Number" },
            ],
            desc: "Lists all quotes where some tracking numbers are not assigned to a PO.",
            tables: ["PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        missingDeliveryTracking: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtDeliveryDate, rcq.dtPickupDate, "
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Mac%' AND rp.nAttachedQuote=rcq.id) AS nMac, "
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Hartford%' AND rp.nAttachedQuote=rcq.id) AS nHartford "
                + "FROM RAC_Customer_Quotes rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.nDeliveryType IN (0,2) AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,-7,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,7,GETDATE()) AND "
                + "rcq.dtFirstClosed < GETDATE() AND rcq.sQuoteType='Rentacomputer.com' "
                + "ORDER BY rcq.dtDeliveryDate asc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtDeliveryDate", label: "Quote Delivery Date" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
                { key: "nMac", label: "Mac POs" },
                { key: "nHartford", label: "Hartford POs" },
            ],
            desc: "Lists all shipping orders due to deliver in the next week that are missing delivery tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        missingReturnTracking: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType,"
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Hartford%' AND rp.nAttachedQuote=rcq.id) AS nHartford "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.id <> '1790676' AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType IN (0,2) AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND nType=2) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtPickupDate >= DATEADD(day,-1,GETDATE()) AND rcq.dtPickupDate <= DATEADD(day,14,GETDATE()) AND "
                + "rcq.dtQuoteDate < GETDATE() "
                + "ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "nHartford", label: "Hartford POs" },
            ],
            desc: "Lists all shipping orders due to return in the next 2 weeks that are missing return tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        packagesDeliveredNotCheckedIn: {
            query: "SELECT rcq.id, rcq.nRep, rcq.dtDeliveryDate, rcq.dtPickupdate, "
                + "(SELECT MAX(p.dtRealDelivery) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 "
                + "AND p.sStatusCode = 'DE' AND (p.bCompleted=0 OR p.bCompleted IS NULL)) AS dtRealDelivery "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE (SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 "
                + "AND p.sStatusCode = 'DE' AND (p.bCompleted=0 OR p.bCompleted IS NULL)) > 0 "
                + "ORDER BY dtRealDelivery",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtRealDelivery", label: "Actual Tracking Delivery Date" },
            ],
            desc: "Shows all tracking that has been delivered, but hasn't been confirmed yet."
                + "Verify customer has received equipment and remind them of our damage policy. "
                + "Deliveries should be checked in by the end of the next day. "
                + "For rentals longer than 5 days, put a follow up in for the rep to check in on the order halfway through the rental period.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        returnsDelivered: {
            query: "SELECT rp.id, p.nQuote, rp.sDisplayId, rp.sCompany AS sVendor, p.dtRealDelivery AS dtReturnDate"
                + " FROM PACKAGES p "
                + " INNER JOIN RAC_POS rp ON p.nPO = rp.id "
                + " WHERE p.nType = 2 AND p.sStatusCode = 'DE' AND (p.bCompleted = 0 OR p.bCompleted IS NULL) AND "
                + " p.dtRealDelivery < GETDATE() "
                + " ORDER BY dtRealDelivery",
            req: [],
            fields: [
                { key: "id", label: "PO ID" },
                { key: "nQuote", label: "Quote ID" },
                { key: "sDisplayId", label: "PO Number" },
                { key: "sVendor", label: "Vendor Name" },
                { key: "dtReturnDate", label: "Actual Return Tracking Delivery Date" },
            ],
            desc: "Shows all return tracking that has been delivered to the vendor but hasn't been confirmed yet."
                + " Verify the vendor received the return and there are no damages. "
                + " Returns must be checked in within 1 week of return. "
                + " After the return is checked in with the vendor, notify the customer and thank them for their business. "
                + " Ask happy customers for reviews. If you suspect the customer is not happy, notify Andy."
                + " To remove vendor deliveries, put 'CHECKED IN' in tracking.",
            tables: ["RAC_POS", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        needsSignedPackingSlip: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.nDeliveryType=1 AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND (p.sTrackingNumber='SPS' OR p.sTrackingNumber='No SPS')) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,-1,GETDATE()) AND rcq.dtDeliveryDate <= GETDATE() "
                + "ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "Lists all quotes that need a signed packing slip sent back after they deliver. "
                + "Notify customer of requirement before delivery. "
                + "Ask for the signed packing slip the day after delivery. "
                + "Mark delivery as 'No SPS' or 'SPS' in tracking to remove them from this list.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        getContactsByCompany: {
            query: "SELECT c.id, c.sName, c.Email1, c.Email2, c.Email3, c.sPhone, c.sRep, c.sMainBrand, c.sTier "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany]",
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "Email1", label: "Email 1" },
                { key: "Email2", label: "Email 2" },
                { key: "Email3", label: "Email 3" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Fetches all contacts associated with a given company ID.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/CreateContactModal.js"],
        },
        createContact: {
            query: `
                INSERT INTO CONTACTS (
                    sName, 
                    Email1, 
                    sPhone, 
                    sRep, 
                    sTier, 
                    nCompany, 
                    dtDateAdded
                )
                VALUES (
                    '[sName]', 
                    '[Email1]', 
                    '[sPhone]', 
                    '[sRep]', 
                    '[sTier]', 
                    [nCompany],
                    GETDATE()
                );
            `,
            req: ["sName", "Email1", "sPhone", "sRep", "sTier", "nCompany"], 
            fields: [
                { key: "sName", label: "Contact Name" },
                { key: "Email1", label: "Email" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sTier", label: "Tier" },
                { key: "nCompany", label: "Company ID" }
            ],
            desc: "Creates a new contact with name, email, phone, rep, tier, and company association in the CONTACTS table, and sets dtDateAdded to the current date and time.",
            tables: ["CONTACTS"],
            type: "INSERT",
            whereUsed: ["components/CompanyDetailsModal.js", "components/CreateContactModal.js"],
        },
        

        deleteContact: {
            query: `
                DECLARE @id INT = [id];
                DELETE FROM CONTACTS
                WHERE id = @id;
            `,
            req: ["id"], // The key `id` must be passed to the query
            fields: [{ key: "id", label: "Contact ID" }],
            desc: "Deletes a contact with the provided ID.",
            tables: ["CONTACTS"],
            type: "DELETE",
        },
        
        topItemDescription: {
            query: `
                DECLARE @contactId INT = [contactId];
                SELECT TOP 1 
                    sDescription AS topItemDescription,
                    SUM(nQuantity) AS totalQuantity
                FROM 
                    QUOTE_LINES
                WHERE 
                    nQuoteId IN (
                        SELECT nQuoteId 
                        FROM CONTACT_ORDERS 
                        WHERE nContactId = @contactId
                    )
                GROUP BY 
                    sDescription
                ORDER BY 
                    totalQuantity DESC;
            `,
            req: ["contactId"], // The key `contactId` must be passed to the query
            fields: [{ key: "contactId", label: "Contact ID" }],
            desc: "Fetches the top item description for a contact based on total quantity across all associated orders.",
            tables: ["QUOTE_LINES", "CONTACT_ORDERS"],
            type: "SELECT",
        },
        
        

        insertCompany: {
            query: "INSERT INTO COMPANIES (sName) " +
                "VALUES ('[sCompany]'); " +
                "SELECT SCOPE_IDENTITY() AS nCompany;", 
            req: ["sCompany"],
            fields: [
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Inserts a new company into the COMPANIES table and returns the new nCompany ID.",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["components/CreateCompanyModal.js"],
        },


        createCompany: {
            query: `
                IF NOT EXISTS (SELECT 1 FROM COMPANIES WHERE sName = '[sCompany]')
                BEGIN
                    INSERT INTO COMPANIES (sName)
                    VALUES ('[sCompany]');
                    SELECT SCOPE_IDENTITY() AS id;
                END
                ELSE
                BEGIN
                    SELECT 'Company already exists' AS message;
                END;
            `,  
            req: ["sCompany"],
            fields: [
                { key: "id", label: "Company ID" },
                { key: "message", label: "Message" },
            ],
            desc: "Inserts a new company into the COMPANIES table if it does not already exist, and returns the new company's ID. If the company already exists, it returns a message indicating so.",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["components/CreateCompanyModal.js"],
        },


        updateFinalReviewStart: {
            query: `
              UPDATE RAC_CUSTOMER_QUOTES
              SET dtFinalReview = GETDATE()
              WHERE id = [quoteId] AND dtFinalReview IS NULL;
            `,
            req: ["quoteId"],
            fields: [
              { key: "quoteId", label: "Quote ID" }
            ],
            desc: "Marks quote as 'in process' by setting dtFinalReview.",
            type: "UPDATE",
        },
        
        updateFinalReviewComplete: {
            query: `
              UPDATE RAC_CUSTOMER_QUOTES
              SET dtFinalReview = GETDATE(), nFinalReview = [nFinalReview]
              WHERE id = [quoteId] AND dtFinalReview IS NOT NULL;
            `,
            req: ["quoteId", "nFinalReview"],
            fields: [
              { key: "quoteId", label: "Quote ID" },
              { key: "nFinalReview", label: "Final Review User ID" }
            ],
            desc: "Completes the review by setting dtFinalReview to the current date and nFinalReview to the user's ID.",
            type: "UPDATE",
        },
        
        
        reviewedQuotesCounts: {
            query: `
              SELECT
                nFinalReview AS rep_id,
                SUM(CASE WHEN dtFinalReview >= DATEADD(day, -1, GETDATE()) THEN 1 ELSE 0 END) AS reviewed_24hrs,
                SUM(CASE WHEN dtFinalReview >= DATEADD(week, -1, GETDATE()) THEN 1 ELSE 0 END) AS reviewed_1week,
                SUM(CASE WHEN dtFinalReview >= DATEADD(month, -1, GETDATE()) THEN 1 ELSE 0 END) AS reviewed_1month
              FROM RAC_CUSTOMER_QUOTES
              WHERE dtFinalReview IS NOT NULL
              GROUP BY nFinalReview;
            `,
            req: [],
            fields: [
              { key: "rep_id", label: "Rep ID" },
              { key: "reviewed_24hrs", label: "Reviewed in Last 24 Hours" },
              { key: "reviewed_1week", label: "Reviewed in Last 1 Week" },
              { key: "reviewed_1month", label: "Reviewed in Last 1 Month" }
            ],
            desc: "Counts the number of quotes reviewed by each representative within different time frames.",
            type: "SELECT"
          },
          

    
          
          



        updateQuoteWithCompany: {
            query: `
            UPDATE RAC_CUSTOMER_QUOTES
            SET nCompany = [nCompany]
            WHERE id = [quoteId]`
            ,
            req: ["nCompany", "quoteId"],
            fields: [
                { key: "nCompany", label: "Company ID" },
                { key: "quoteId", label: "Quote ID" },
            ],
            desc: "Updates the RAC_CUSTOMER_QUOTES table with the provided nCompany ID for the given quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/CreateCompanyModal.js"],
        },

        closedQuotes: {
            query: `
                SELECT TOP 250 
                    rcq.id,
                    rcq.nCompany,
                    rcq.sCompany,
                    rcq.sStatus,
                    rcq.dtFirstClosed,
                    rcq.nQuoteTotal,
                    rcq.sCity,
                    rcq.sState,
                    rcq.sZip,
                    rcq.sName AS PrimaryContactName,
                    rcq.sPhone AS PrimaryContactPhone,
                    rcq.sEmail AS PrimaryContactEmail,
                    rcq.sQuoteType AS CFRBrand,
                    rcq.nRep,
                    COALESCE(SUM(rp.nPOTotal), 0) AS TotalCost
                FROM RAC_CUSTOMER_QUOTES rcq
                LEFT JOIN RAC_POS rp ON rcq.id = rp.nAttachedQuote
                WHERE rcq.dtFirstClosed IS NOT NULL 
                    AND rcq.dtFirstClosed >= '2024-10-01'
                    AND (rcq.dtFinalReview IS NULL OR (rcq.dtFinalReview IS NOT NULL AND rcq.nFinalReview IS NULL))
                    AND rcq.id <> 1766971
                    AND rcq.nParentQuote IS NULL
                GROUP BY 
                    rcq.id, rcq.nCompany, 
                    rcq.sCompany, rcq.sStatus, rcq.dtFirstClosed, rcq.nQuoteTotal,
                    rcq.sCity, rcq.sState, rcq.sZip, rcq.sName, rcq.sPhone, rcq.sEmail,
                    rcq.sQuoteType, rcq.nRep
                ORDER BY rcq.dtFirstClosed DESC
            `,
            req: [],
            fields: [
                { key: "id", label: "Quote ID" },
                { key: "nCompany", label: "Company ID" },
                { key: "sCompany", label: "Company Name" },
                { key: "sStatus", label: "Status" },
                { key: "dtFirstClosed", label: "Closed Date" },
                { key: "nQuoteTotal", label: "Order Dollar Amount" },
                { key: "sCity", label: "City" },
                { key: "sState", label: "State" },
                { key: "sZip", label: "Zip Code" },
                { key: "PrimaryContactName", label: "Primary Contact Name" },
                { key: "PrimaryContactPhone", label: "Primary Contact Phone" },
                { key: "PrimaryContactEmail", label: "Primary Contact Email" },
                { key: "CFRBrand", label: "CFR Brand" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "TotalCost", label: "Total PO Cost" }
            ],
            desc: "Fetches the most recent closed quote that either has no final review or is in progress, excluding quote ID 1766971, rebills, and only showing quotes where dtFirstClosed is after 10/1/2024. It includes the summed total cost of all associated POs for accurate margin calculation.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_POS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },




        discontinuedQuotes: {
            query: `
                SELECT TOP 250
                    rcq.id, 
                    rcq.nCompany,  -- Include the company ID
                    rcq.sCompany, 
                    rcq.sStatus, 
                    rcq.dtLastUpdated,
                    rcq.nQuoteTotal,
                    rcq.sCity,
                    rcq.sState,
                    rcq.sZip,
                    rcq.sName AS PrimaryContactName,  -- Use sName for Primary Contact Name
                    rcq.sPhone AS PrimaryContactPhone, -- Use sPhone for Primary Contact Phone
                    rcq.sEmail AS PrimaryContactEmail,  -- Use sEmail for Primary Contact Email
                    rcq.sQuoteType AS CFRBrand,
                    rcq.sWhyCancel,
                    rcq.nRep,
                    COALESCE(SUM(rp.nPOTotal), 0) AS TotalCost
                FROM RAC_CUSTOMER_QUOTES rcq
                LEFT JOIN RAC_POS rp ON rcq.id = rp.nAttachedQuote
                WHERE rcq.sStatus = 'Discontinued'
                    AND rcq.nParentQuote IS NULL
                    AND rcq.dtFinalReview IS NULL  -- Include only quotes without a completed review
                GROUP BY 
                    rcq.id, rcq.nCompany,  -- Group by nCompany as well
                    rcq.sCompany, rcq.sStatus, rcq.dtLastUpdated, rcq.nQuoteTotal,
                    rcq.sCity, rcq.sState, rcq.sZip, rcq.sName, rcq.sPhone, rcq.sEmail,
                    rcq.sQuoteType, rcq.sWhyCancel, rcq.nRep
                ORDER BY rcq.dtLastUpdated DESC
            `,
            req: [],
            fields: [
                { key: "id", label: "Quote ID" },
                { key: "nCompany", label: "Company ID" },  // Add the Company ID field
                { key: "sCompany", label: "Company Name" },
                { key: "sStatus", label: "Status" },
                { key: "dtLastUpdated", label: "Last Updated" },
                { key: "nQuoteTotal", label: "Order Dollar Amount" },
                { key: "TotalCost", label: "Total PO Cost" },
                { key: "sCity", label: "City" },
                { key: "sState", label: "State" },
                { key: "sZip", label: "Zip Code" },
                { key: "PrimaryContactName", label: "Primary Contact Name" },
                { key: "PrimaryContactPhone", label: "Primary Contact Phone" },
                { key: "PrimaryContactEmail", label: "Primary Contact Email" },
                { key: "CFRBrand", label: "CFR Brand" },
                { key: "sWhyCancel", label: "Reason for Cancellation" },
                { key: "nRep", label: "Sales Rep ID" },
            ],
            desc: "Fetches the most recent discontinued quotes with no final review, excluding rebills, including aggregated PO costs for accurate sales margin and the reason for cancellation.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_POS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },



        notesOpen: {
            query: `
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn 
                WHERE rqn.nQuote IN (
                    SELECT rcq.id 
                    FROM RAC_CUSTOMER_QUOTES rcq 
                    WHERE rcq.sStatus = 'Open'
                )
                ORDER BY rqn.dtTimeStamp DESC
            `,
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to open quotes, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        notesClosed: {
            query: `
                DECLARE @quoteId INT = [quoteId];
        
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn
                WHERE rqn.nQuote = @quoteId
            `,
            req: [{ key: "quoteId", type: "int" }],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to a specific closed quote, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },


        notesDiscontinued: {
            query: `
                DECLARE @quoteId INT = [quoteId];
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn
                WHERE rqn.nQuote = @quoteId
            `,
            req: [{ key: "quoteId", type: "int" }],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to a specific discontinued quote, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },



        checkCompanyByName: {
            query: "SELECT c.id AS nCompany "
                + "FROM COMPANIES c "
                + "WHERE LOWER(TRIM(c.sName)) = LOWER(TRIM('[sCompany]'))",
            req: ["sCompany"],
            fields: [
                { key: "nCompany", label: "Company ID" }
            ],
            desc: "Checks if a company exists in the COMPANIES table by name (sCompany) and returns its ID.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: ["components/DiscontinuedQuotesModal.js"],
        },



        checkCompanyExists: {
            query: `
              SELECT id
              FROM COMPANIES
              WHERE id = [nCompany]
            `,
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Company ID" }
            ],
            desc: "Checks if a company with the given nCompany value exists in the COMPANIES table.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },


        emailsOpen: {
            query: "SELECT rqe.nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject " +
                "FROM RAC_QUOTE_EMAILS rqe " +
                "WHERE rqe.nQuote IN ( " +
                "SELECT TOP 10 rcq.id " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.sStatus = 'Open' " +
                "ORDER BY rcq.dtQuoteDate DESC)",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to the most recently opened quotes, including the email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        emailsClosed: {
            query: `
                DECLARE @quoteId INT = [quoteId];
        
                SELECT rqe.id AS nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject
                FROM RAC_QUOTE_EMAILS rqe
                WHERE rqe.nQuote = @quoteId
            `,
            req: [{ key: "quoteId", type: "int" }],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to a specific closed quote, including email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },


        emailsDiscontinued: {
            query: `
                DECLARE @quoteId INT = [quoteId];
                SELECT rqe.nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject
                FROM RAC_QUOTE_EMAILS rqe
                WHERE rqe.nQuote = @quoteId
            `,
            req: [{ key: "quoteId", type: "int" }],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to a specific discontinued quote, including email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },


        contactsClosed: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed IS NOT NULL AND rcq.dtManagerReviewed IS NULL "
                + "ORDER BY rcq.dtFirstClosed DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts related to the most recently closed quotes.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        contactsDiscontinued: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Discontinued' AND rcq.bDiscontinuedValid <> 1 "
                + "ORDER BY rcq.dtLastUpdated DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts related to the most recently discontinued quotes.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        contactsForSpecificCompany: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany]",
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts for a specific company (nCompany).",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/CompanyDetailsModal.js"],
        },




        updateContact: {
            query: `
                UPDATE CONTACTS
                SET 
                    sName = '[sName]', 
                    nCompany = [nCompany], 
                    Email1 = '[Email1]', 
                    Email2 = '[Email2]', 
                    Email3 = '[Email3]', 
                    sPhone = '[sPhone]', 
                    sRep = [sRep], 
                    sMainBrand = '[sMainBrand]', 
                    sTier = '[sTier]'
                WHERE id = [id];
            `,
            req: ["id", "sName", "nCompany", "Email1", "Email2", "Email3", "sPhone", "sRep", "sMainBrand", "sTier"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Email 1" },
                { key: "Email2", label: "Email 2" },
                { key: "Email3", label: "Email 3" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Updates an existing contact with the provided details.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: ["components/QuoteManager.js"],
        },

        logMarketingContact: {
            query: "INSERT INTO CONTACT_LOG "
                + "(dtDate, nRep, nContact, sType, sDisposition, sNote) "
                + "VALUES (GETDATE(), [nRep], [nContact], '[sType]', '[sDisposition]', '[sNote]')",
            req: ["nRep", "nContact", "sType", "sDisposition", "sNote"],
            fields: [
                { key: "dtDate", label: "Date" },
                { key: "nRep", label: "Rep ID" },
                { key: "nContact", label: "Contact ID" },
                { key: "sType", label: "Contact Type" },
                { key: "sDisposition", label: "Disposition" },
                { key: "sNote", label: "Note" },
            ],
            desc: "Logs a marketing contact made by a rep.",
            tables: ["CONTACT_LOG"],
            type: "INSERT",
            whereUsed: ["components/QuoteManager.js"],
        },

        getQuoteDetailsById: {
            query: `
                DECLARE @quoteId INT = [quoteId];
                SELECT sName, sEmail, sPhone, nRep, sStatus  -- Added sStatus to the selection
                FROM RAC_CUSTOMER_QUOTES 
                WHERE id = @quoteId;
            `,
            req: ["quoteId"], // Declaring the required parameter 'quoteId'
            fields: [
                { key: "sName", label: "Contact Name" },
                { key: "sEmail", label: "Email" },
                { key: "sPhone", label: "Phone" },
                { key: "nRep", label: "Rep ID" },
                { key: "sStatus", label: "Quote Status" }  // Added sStatus to the fields
            ],
            desc: "Fetches contact details and status from RAC_CUSTOMER_QUOTES based on the provided quote ID.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["components/CreateContactModal.js", "components/CompanyDetailsModal.js"],
        },

        updateContactTier: {
            query: "UPDATE CONTACTS SET sTier = '[sTier]' WHERE id = [nContact]",
            req: ["sTier", "nContact"],
            fields: [
                { key: "sTier", label: "Tier" },
                { key: "nContact", label: "Contact ID" },
            ],
            desc: "Updates the tier (sTier) for the specified contact (nContact) in the CONTACTS table.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: ["components/QuoteManager.js"],
        },
        quoteLines: {
            query: `
                SELECT 
                    rql.id, 
                    rql.nQuote, 
                    rql.nQuantity, 
                    rql.sDescription, 
                    rql.nPrice, 
                    rql.nPO, 
                    rql.bTax, 
                    rql.nSort, 
                    rql.nItem
                FROM RAC_QUOTE_LINES rql
                WHERE rql.nQuote IN ([quoteIds])
                ORDER BY rql.nQuote, rql.nSort ASC
            `,
            req: ["quoteIds"],  // Provide quoteIds as a comma-separated string
            fields: [
                { key: "id", label: "Line ID" },
                { key: "nQuote", label: "Quote ID" },
                { key: "nQuantity", label: "Quantity" },
                { key: "sDescription", label: "Description" },
                { key: "nPrice", label: "Price" },
                { key: "nPO", label: "PO Number" },
                { key: "bTax", label: "Taxable" },
                { key: "nSort", label: "Sort Order" },
                { key: "nItem", label: "Item ID" },
            ],
            desc: "Fetches quote line items for specified quotes, ordered by quote ID and sort order.",
            tables: ["RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: ["components/ClosedQuotesModal.js", "components/DiscontinuedQuotesModal.js"],
        },

    },
};