import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreenText, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';

export default class OpenInstallTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: [],
    };
  }

  expandNotes(index){
    let expand = this.state.expand
    expand[index] = (expand[index] === 1 ? 0 : 1)
    this.setState({
      expand: expand,
    })
  }

  render() {
    
    return (
      <>
        <Title>{this.props.title}</Title>
        <div style={{ paddingBottom: '10px' }}>{this.props.instructions}</div>

        {this.props.data.length > 0 && (
          <div>
            {this.props.data.map((sql, index) => (
              <table
                key={sql.id}
                style={{ border: '1px solid black', borderRadius: '5px 5px 5px 5px' }}
              >
                <tr>
                  <td style={{ verticalAlign: 'top', textAlign: 'left', width: '300px' }}>
                    <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                      <Link target="_blank" to={`/quote/${sql.id}`}>
                        Quote {sql.id} <br /> {sql.sQuoteTitle}
                      </Link>
                    </div>
                    <div>{sql.sDCompany}</div>
                    <div>
                      {sql.sDCity}, {sql.sDState} {sql.sDZip}
                    </div>
                    <div>{sql.sDName}</div>
                    <div>{sql.sDPhone}</div>
                  </td>
                  <td style={{ verticalAlign: 'top', textAlign: 'left', width: '300px' }}>
                    <div>
                      <b>Price</b>: {nQuoteTotalNoDollar(sql.nQuoteTotal)}
                    </div>
                    <div>
                      <b>Margin</b>: {nQuoteTotalNoDollar(sql.nMargin)}
                    </div>
                    <div onClick={() => this.props.handleShowPO(sql.index)}>
                      <b>CSN Labor</b>: <a style={{ color: 'blue' }}>{nQuoteTotalNoDollar(sql.nLaborTotals)}</a>
                    </div>
                    <div>
                      <b>First Closed</b>: {moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}
                    </div>
                    <div>
                      <b>Rep</b>: {sql.nRep ? whichRep(sql.nRep) : null}
                    </div>
                    <div>
                      <b>Tech</b>: {sql.nRep2 ? whichRep(sql.nRep2) : (
                        <>
                        <br /><button onClick={()=>this.props.handleUpdateRep2(sql.id, 63)}>Assign Hunter</button>
                        <br /><button onClick={()=>this.props.handleUpdateRep2(sql.id, 44)}>Assign KB</button>
                        <br /><button onClick={()=>this.props.handleUpdateRep2(sql.id, 68)}>Assign KT</button>
                        </>
                      )}
                    </div>
                  </td>

                  <td style={{ verticalAlign: 'top', textAlign: 'left', width: '300px' }}>
                    <div>
                      <b>Hardware Expected</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtHardwareExpected');
                          }}
                          type="date"
                          id="date"
                          name="date"
                          value={moment(sql.dtHardwareExpected).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() =>
                          this.props.handleDateSubmit(sql.dtHardwareExpected, sql.id, 'dtHardwareExpected')
                        }
                      >
                        Submit
                      </SubmitText>
                    </div>
                    <div>
                      <b>Install Start Date</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtTargetStart');
                          }}
                          type="date"
                          id="date"
                          name="date"
                          value={moment(sql.dtTargetStart).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() => this.props.handleDateSubmit(sql.dtTargetStart, sql.id, 'dtTargetStart')}
                      >
                        Submit
                      </SubmitText>
                      <br />
                      <input
                        type="checkbox"
                        checked={sql.bLaborOnly}
                        onClick={() => this.props.setLaborOnly(sql)}
                      />{' '}
                      Labor Only
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'top', textAlign: 'left', width: '300px' }}>
                    <div>
                      <b>Hardware Setup</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtHardwareSetup');
                          }}
                          type="date"
                          id="date"
                          name="date"
                          value={moment(sql.dtHardwareSetup).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() =>
                          this.props.handleDateSubmit(sql.dtHardwareSetup, sql.id, 'dtHardwareSetup')
                        }
                      >
                        Submit
                      </SubmitText>
                    </div>
                    <div>
                      <b>Target Complete</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtTargetComplete');
                          }}
                          type="date"
                          id="date"
                          name="date"
                          value={moment(sql.dtTargetComplete).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() =>
                          this.props.handleDateSubmit(sql.dtTargetComplete, sql.id, 'dtTargetComplete')
                        }
                      >
                        Submit
                      </SubmitText>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'top', textAlign: 'left', width: '300px' }}>
                    <div>
                      <b>Ship Date</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtShipDate');
                          }}
                          type="date"
                          id="target"
                          name="target"
                          value={moment(sql.dtShipDate).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() => this.props.handleDateSubmit(sql.dtShipDate, sql.id, 'dtShipDate')}
                      >
                        Submit
                      </SubmitText>
                    </div>
                    <div>
                      <b>Actual Complete</b>:
                      <CustomDateWrapper>
                        <Input
                          onChange={(e) => {
                            this.props.handleDateChange(e, sql.index, 'dtComplete');
                          }}
                          type="date"
                          id="target"
                          name="target"
                          value={moment(sql.dtComplete).utc().format('YYYY-MM-DD')}
                        />
                      </CustomDateWrapper>
                      <SubmitText
                        onClick={() => this.props.handleDateSubmit(sql.dtComplete, sql.id, 'dtComplete')}
                      >
                        Submit
                      </SubmitText>
                    </div>
                  </td>
                </tr>
                <tr>
                <td colSpan={2} style={{ verticalAlign: 'top', textAlign: 'left' }}>
  <div><b>Notes</b></div>
  {sql.notes.map((note, idx) => {
    const nAttention = Array.isArray(note.nAttention) ? note.nAttention[0] : note.nAttention;
    const nAttentionPriority = Array.isArray(note.nAttentionPriority) ? note.nAttentionPriority[0] : note.nAttentionPriority;
    const attentionRep = nAttention && nAttention !== 0 
      ? (whichRep(nAttention) || `Rep ID: ${nAttention}`)
      : null;
    const noteStyle = nAttentionPriority === 1 ? { color: 'darkred' } : {};
    const expand = this.state.expand[index] === 1 ? true : false

    return (
      <>
      {(idx < 6 || expand) && (
      <div key={idx} style={{ margin: '10px', ...noteStyle }}>
        <div>
          <b>{whichRep(note.nRep)}{' '}
            {moment(note.dtTimeStamp).utc().format('hh:mm A MM/DD/YYYY')}
          </b>
        </div>
        {attentionRep && (
          <div>
            ATTN: <b>{attentionRep}</b>
          </div>
        )}
        <div>{note.sNote}</div>
      </div>
      )}
      
      </>
    );
  })}

  {(this.state.expand[index] === 1 ? true : false) && (<button onClick={()=>this.expandNotes(index)}>Collapse Notes</button>)}
  {(!(this.state.expand[index] === 1) && sql.notes.length > 6) && (<button onClick={()=>this.expandNotes(index)}>Expand Notes</button>)}
</td>
<td colSpan={1} style={{verticalAlign: 'top', textAlign:'left'}}>
    <div><b>Followups</b></div>
        {sql.followups.map((followup, index)=>(
        <>
        {(!followup.dtCompleted && followup.bActive) && (
          <div style={{margin: '10px'}}>
            <div><b>{whichRep(followup.nRep)} {moment(followup.dtDate).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
            <div>{followup.sNote}</div>
          </div>
        )}
        </>
      ))}

</td>


                  <td colSpan={1} style={{ verticalAlign: 'top', textAlign: 'left' }}>
                    <div><b>Inbound Tracking</b></div>
                    {sql.inbound.map((tracking, index2) => (
                      <div style={{ color: tracking.sStatusCode === 'DE' ? cGreenText : '' }}>
                        <input
                          type="checkbox"
                          checked={tracking.bCompleted}
                          onClick={() => this.props.handleConfirmShipment(tracking)}
                        ></input>
                        <a
                          href={'https://www.google.com/search?q=' + tracking.sTrackingNumber}
                          target="_blank"
                        >
                          {tracking.sTrackingNumber} {tracking.sStatusCode}{' '}
                          {tracking.sStatusCode === 'DE'
                            ? moment(tracking.dtRealDelivery).format('MM-DD')
                            : moment(tracking.dtEstimatedDelivery).format('MM-DD')}
                        </a>
                      </div>
                    ))}
                  </td>
                  <td colSpan={1} style={{ verticalAlign: 'top', textAlign: 'left' }}>
                    <div><b>Outbound Tracking</b></div>
                    {sql.outbound.map((tracking, index2) => (
                      <div style={{ color: tracking.sStatusCode === 'DE' ? cGreenText : '' }}>
                        <input
                          type="checkbox"
                          checked={tracking.bCompleted}
                          onClick={() => this.props.handleConfirmShipment(tracking)}
                        ></input>
                        <a
                          href={'https://www.google.com/search?q=' + tracking.sTrackingNumber}
                          target="_blank"
                        >
                          {tracking.sTrackingNumber} {tracking.sStatusCode}{' '}
                          {tracking.sStatusCode === 'DE'
                            ? moment(tracking.dtRealDelivery).format('MM-DD')
                            : moment(tracking.dtEstimatedDelivery).format('MM-DD')}
                        </a>
                      </div>
                    ))}
                  </td>
                </tr>
                {sql.showPOs && (
                  <tr>
                    <td colSpan={'100%'}>
                      <div>
                        <table className="dashboard">
                          <thead>
                            <tr>
                              <th>Vendor</th>
                              <th>Description</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total Price</th>
                              <th>Item</th>
                            </tr>
                          </thead>
                          {sql.POLines && sql.POLines.map((line, lineNumber) => (
                            <tr key={lineNumber}>
                              <td>{line.sCompany}</td>
                              <td>{line.sDescription}</td>
                              <td>{line.nQuantity}</td>
                              <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                              <td>{nQuoteTotalNoDollar(line.nQuantity * line.nPrice)}</td>
                              <td
                                onClick={() => this.props.handleSetLabor(sql.index, lineNumber)}
                              >
                                {line.nItem === 832 ? (
                                  <>CSN Labor</>
                                ) : (
                                  <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                                )}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            ))}
          </div>
        )}
      </>
    );
  }
}