import React, { Component } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ContactList,
  ContactItem,
  InputGroup,
  ButtonGroup,
  SaveButton,
  DeleteButton,
  EditButton,
  Button
} from './StyledComponents';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};


// Define the list of industries and sub-industries
const industryAndSubIndustryList = [
  "Accounting Services",
  "Advertising & Marketing",
  "Aerospace & Defense",
  "Agriculture",
  "Airlines, Airports & Air Services",
  "Ambulance Services",
"Amusement Parks, Arcades & Attractions",
"Animals & Livestock",
"Apparel & Accessories Retail",
"Appliances",
  "Architecture, Engineering & Design",
  "Auctions",
  "Automobile Dealers",
  "Automobile Parts Stores",
  "Automotive Parts",
  "Automotive Service & Collision Repair",
  "Banking",
  "Barber Shops & Beauty Salons",
  "Blood & Organ Banks",
  "Boats & Submarines",
  "Broadcasting",
  "Building Materials",
  "Business Intelligence (BI) Software",
  "Business Services",
  "Cable & Satellite",
  "Call Centers & Business Centers",
  "Car & Truck Rental",
  "Chambers of Commerce",
  "Chemicals & Related Products",
  "Childcare",
  "Civil Engineering Construction",
  "Cleaning Products",
  "Cleaning Services",
  "Colleges & Universities",
  "Commercial & Residential Construction",
  "Commercial Printing",
  "Computer Equipment & Peripherals",
  "Construction",
  "Consumer Electronics & Computers Retail",
  "Consumer Services",
  "Content & Collaboration Software",
  "Convenience Stores, Gas Stations, & Liquor Stores",
  "Cosmetics, Beauty Supply, & Personal Care Products",
  "Credit Cards & Transaction Processing",
  "Crops",
  "Cultural & Informational Centers",
  "Custom Software & IT Services",
  "Customer Relationship Management (CRM) Software",
  "Data Collection & Internet Portals",
  "Database & File Management Software",
  "Dental Offices",
  "Department Stores, Shopping Centers, & Superstores",
"Drug Stores & Pharmacies",
"Education",
"Elderly Care Services",
"Electricity, Oil, & Gas",
"Electronics",
"Energy, Utilities, & Waste",
"Engineering Software",
"Enterprise Resource Planning (ERP) Software",
"Facilities Management & Commercial Cleaning",
"Federal",
"Finance",
"Financial Software",
"Fitness & Dance Facilities",
"Flowers, Gifts, & Specialty Stores",
"Food & Beverage",
"Food Service",
"Forestry",
"Freight & Logistics Services",
"Funeral Homes & Funeral Related Services",
"Furniture",
"Gambling & Gaming",
"Glass and Clay",
"Government",
"Grocery Retail",
"Hand, Power, & Lawn-Care Tools",
"Health & Nutrition Products",
"Healthcare Services",
"Healthcare Software",
"Holding Companies & Conglomerates",
"Home Improvement & Hardware Retail",
"Hospitality",
"Hospitals  & Physicians Clinics",
"Household Goods",
"HR & Staffing",
"Human Resources Software",
"Industrial Machinery & Equipment",
"Information & Document Management",
"Insurance",
"Internet Service Providers, Website Hosting, & Internet Related Services",
"Investment Banking",
"Jewelry & Watch Retail",
"K-12 Schools",
"Landscape Services",
"Law Firms & Legal Services",
"Legal Software",
"Lending & Brokerage",
"Libraries",
"Local",
"Lodging & Resorts",
"Management Consulting",
"Manufacturing",
"Marine Shipping & Transportation",
"Media & Internet",
"Medical & Surgical Hospitals",
"Medical Devices & Equipment",
"Medical Laboratories & Imaging Centers",
"Medical Specialists",
"Membership Organizations",
"Mental Health & Rehabilitation Facilities",
"Minerals & Mining",
"Mobile App Development",
"Motor Vehicles",
"Movie Theaters",
"Multimedia & Graphic Design",
"Multimedia, Games, & Graphics Software",
"Museums & Art Galleries",
"Music Production & Services",
"N/A",
"Networking Software",
"Newspapers & News Services",
"Non-Profit & Charitable Organizations",
"Office Products Retail & Distribution",
"Oil & Gas Exploration Services",
"Organizations",
"Other Rental Stores (Furniture, A/V, Construction, & Industrial Equipment)",
"Performing Arts Theaters",
"Pet Products",
"Phamaceuticals",
"Photographic & Optical Equipment",
"Photography Studio",
"Physicians Clinics",
"Plastic, Packaging, & Containers",
"Publishing",
"Pulp & Paper",
"Rail, Bus, & Taxi",
"Real Estate",
"Record, Video, & Book Stores",
"Religious Organizations",
"Repair Services",
"Research & Development",
"Restaurants",
"Retail",
"Security Products & Services",
"Security Software",
"Services",
"Social Networks",
"Software",
"Sporting & Recreational Equipment Retail",
"Sporting Goods",
"Sports Teams & Leagues",
"State",
"Storage & System Management Software",
"Supply Chain Management (SCM) Software",
"Telecommunication Equipment",
"Telecommunications",
"Telephony & Wireless",
"Test & Measurement Equipment",
"Textiles & Apparel",
"Ticket Sales",
"Tires & Rubber",
"Toys & Games",
"Training",
"Translation & Linguistic Services",
"Transportation",
"Travel Agencies & Services",
"Tribal Nations",
"Trucking, Moving & Storage",
"Venture Capital & Private Equity",
"Veterinary Services",
"Vitamins, Supplements, & Health Stores",
"Waste Treatment, Enviornmental Services, & Recycling",
"Watches & Jewelry",
"Water Treatment",
"Weight & Health Management",
"Wire & Cable",
"Zoos & National Parks",
];


class CompanyDetailsModal extends Component {
  constructor(props) {
    super(props);

    // Bind methods
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCompanyInputChange = this.handleCompanyInputChange.bind(this);
    this.handleSaveCompanyDetails = this.handleSaveCompanyDetails.bind(this);
    this.handleCreateContact = this.handleCreateContact.bind(this);
    this.handleNewContactChange = this.handleNewContactChange.bind(this);
    this.handleSubmitNewContact = this.handleSubmitNewContact.bind(this);
    this.handleSubmitEditContact = this.handleSubmitEditContact.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  state = {
    contacts: [],
    editingContactId: null,
    contactEdits: {},
    loading: true,
    error: null,
    newContact: {
      name: '',
      email: '',
      phone: '',
      rep: this.props.repId || '',
      tier: '3'
    },
    creatingContact: false,
    companyDetails: {
      industry: '',
      subIndustry: '',
      employees: '',
      annualSales: '',
    },
    editingCompany: false,
    saveSuccess: false,
  };

  componentDidMount() {
    const { companyId, contactInfo } = this.props;

    if (companyId) {
      this.fetchCompanyContacts();
      this.fetchCompanyDetails();
    }

    if (contactInfo) {
      this.setState({
        newContact: {
          name: contactInfo.name || '',
          email: contactInfo.email || '',
          phone: contactInfo.phone || '',
          rep: this.props.repId || '',
          tier: '3',
        },
      });
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.resetStateForNewCompany();
      this.fetchCompanyContacts();
      this.fetchCompanyDetails();
    }

    if (this.props.contactInfo !== prevProps.contactInfo && this.props.contactInfo) {
      this.setState({
        newContact: {
          name: this.props.contactInfo.name || '',
          email: this.props.contactInfo.email || '',
          phone: this.props.contactInfo.phone || '',
          rep: this.props.repId || '',
          tier: '3',
        },
      });
    }
  }


  resetStateForNewCompany = () => {
    this.setState({
      contacts: [],
      editingContactId: null,
      contactEdits: {},
      loading: true,
      error: null,
      newContact: {
        name: '',
        email: '',
        phone: '',
        rep: this.props.repId || '',
        tier: '3'
      },
      creatingContact: false,
      companyDetails: {
        industry: '',
        subIndustry: '',
        employees: '',
        annualSales: '',
      },
      editingCompany: false,
      saveSuccess: false,
    });
  };

  fetchCompanyContacts = async () => {
    const { companyId } = this.props;
    try {
      const contactsFetch = await getSQLData("logistics/getContactsByCompany", { nCompany: companyId });
      const contacts = contactsFetch.data?.data[0]?.recordset || [];
      this.setState({ contacts, loading: false });
    } catch (error) {
      console.error('Error fetching contacts:', error);
      this.setState({ loading: false, error: 'Error fetching contacts.' });
    }
  };

  fetchCompanyDetails = async () => {
    const { companyId } = this.props;
    try {
      const response = await getSQLData('marketing/getCustomerData', { id: companyId });
      const company = response.data?.data[0]?.recordset[0] || {};
      this.setState({
        companyDetails: {
          industry: company.sIndustry || '',
          subIndustry: company.sSubIndustry || '',
          employees: company.nEmployees || '',
          annualSales: company.nCompanyAnnualSales || '',
        },
        saveSuccess: false,
      });
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  handleCompanyInputChange(field, value) {
    this.setState((prevState) => ({
      companyDetails: {
        ...prevState.companyDetails,
        [field]: value,
      },
      saveSuccess: false,
    }));
  }

  handleSaveCompanyDetails = async () => {
    const { companyId } = this.props;
    const { industry, subIndustry, employees, annualSales } = this.state.companyDetails;
    try {
      await setSQLData('marketing/setCompanyProfileData', {
        companyId,
        sIndustry: industry,
        sSubIndustry: subIndustry,
        nEmployees: employees,
        nCompanyAnnualSales: annualSales,
      });
      this.setState({ saveSuccess: true, editingCompany: false });
    } catch (error) {
      console.error('Error saving company details:', error);
    }
  };

  handleSubmitEditContact = async () => {
    const { contactEdits, editingContactId } = this.state;
    const { repId, companyId } = this.props;

    // Safeguard: Ensure we have a valid contact ID before updating
    if (!editingContactId) {
      console.error("Error: No valid contact ID provided for updating.");
      return;
    }

    const contactData = {
      id: editingContactId,
      sName: contactEdits.sName,
      Email1: contactEdits.Email1,
      sPhone: contactEdits.sPhone,
      sRep: contactEdits.sRep || repId,
      sTier: contactEdits.sTier,
      nCompany: companyId,
    };

    try {
      const response = await setSQLData('logistics/updateContact', contactData);

      if (response?.status === 200) {
        this.setState((prevState) => ({
          contacts: prevState.contacts.map((contact) =>
            contact.id === editingContactId ? { ...contact, ...contactData } : contact
          ),
          contactEdits: { sName: '', Email1: '', sPhone: '', sRep: '', sTier: '3' },
          editingContactId: null,
        }));
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  handleEditClick(contactId) {
    const contact = this.state.contacts.find(c => c.id === contactId);
    if (contact) {
      this.setState({
        editingContactId: contactId,
        contactEdits: {
          sName: contact.sName,
          sPhone: contact.sPhone,
          Email1: contact.Email1,
          sRep: contact.sRep || '',
          sTier: contact.sTier || '3',
        },
      });
    }
  }

  handleSaveClick = async () => {
    const { editingContactId, contactEdits, contacts } = this.state;
    const { companyId } = this.props;

    // Safeguard: Ensure a valid contact ID is present
    if (!editingContactId) {
      console.error("No valid contact ID for updating.");
      return;
    }

    try {
      await setSQLData('logistics/updateContact', {
        id: editingContactId,
        ...contactEdits,
        nCompany: companyId,
      });
      this.setState({
        contacts: contacts.map((contact) =>
          contact.id === editingContactId ? { ...contact, ...contactEdits } : contact
        ),
        editingContactId: null,
        contactEdits: {},
      });
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  handleDeleteClick = async () => {
    const { editingContactId, contacts } = this.state;

    // Safeguard: Ensure a valid contact ID is provided
    if (!editingContactId || isNaN(editingContactId)) {
        alert("Error: No valid contact ID provided for deletion.");
        console.error("Invalid or missing contact ID:", editingContactId);
        return;
    }

    // Find the contact to delete
    const contactToDelete = contacts.find(contact => contact.id === editingContactId);
    if (!contactToDelete) {
        alert("Error: Contact not found for deletion.");
        console.error("Contact with ID not found:", editingContactId);
        return;
    }

    const contactName = contactToDelete.sName || "this contact";

    // Show a confirmation dialog
    const confirmDelete = window.confirm(
        `Are you sure you want to delete ${contactName}? This action cannot be undone.`
    );
    if (!confirmDelete) {
        console.log("Deletion cancelled by the user.");
        return; // Exit if the user cancels
    }

    try {
        // Proceed with deletion
        await setSQLData('logistics/deleteContact', { id: editingContactId });

        // Update the state to remove the deleted contact
        this.setState((prevState) => ({
            contacts: prevState.contacts.filter(contact => contact.id !== editingContactId),
            editingContactId: null,
            contactEdits: {},
        }));

        // Notify the user of successful deletion
        alert(`Successfully deleted ${contactName}.`);
    } catch (error) {
        console.error('Error deleting contact:', error);
        alert("Failed to delete the contact. Please try again.");
    }
};



  handleCreateContact() {
    this.setState({ creatingContact: true });
  }

  handleNewContactChange(field, value) {
    this.setState((prevState) => ({
      newContact: {
        ...prevState.newContact,
        [field]: value,
      },
    }));
  }

  handleSubmitNewContact = async () => {
    const { newContact } = this.state;
    const { repId, companyId } = this.props;
    const contactData = {
      sName: newContact.name,
      Email1: newContact.email,
      sPhone: newContact.phone,
      sRep: newContact.rep || repId,
      sTier: newContact.tier,
      nCompany: companyId,
    };

    try {
      const response = await setSQLData('logistics/createContact', contactData);
      if (response?.status === 200) {
        this.setState((prevState) => ({
          contacts: [...prevState.contacts, contactData],
          newContact: { name: '', email: '', phone: '', rep: '', tier: '3' },
          creatingContact: false,
        }));
      }
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  render() {
    const { onClose, companyName } = this.props;
    const { contacts, editingContactId, contactEdits, loading, error, newContact, creatingContact, editingCompany, companyDetails, saveSuccess } = this.state;

    return (
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>{companyName} Details</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <p>Loading contacts...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <h3>Company Information</h3>
              <div>
                {editingCompany ? (
                  <>
                    <InputGroup>
                      <label>Industry:</label>
                      <select
                        value={companyDetails.industry}
                        onChange={(e) => this.handleCompanyInputChange("industry", e.target.value)}
                      >
                        <option value="">Select Industry</option>
                        {industryAndSubIndustryList.map((entry) => (
                          <option key={entry} value={entry}>
                            {entry}
                          </option>
                        ))}
                      </select>
                    </InputGroup>
                    <InputGroup>
                      <label>Sub-Industry:</label>
                      <select
                        value={companyDetails.subIndustry}
                        onChange={(e) => this.handleCompanyInputChange("subIndustry", e.target.value)}
                      >
                        <option value="">Select Sub-Industry</option>
                        {industryAndSubIndustryList.map((entry) => (
                          <option key={entry} value={entry}>
                            {entry}
                          </option>
                        ))}
                      </select>
                    </InputGroup>
                    <InputGroup>
                      <label>Employees:</label>
                      <input
                        type="number"
                        value={companyDetails.employees}
                        onChange={(e) => this.handleCompanyInputChange("employees", parseInt(e.target.value) || 0)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Annual Revenue:</label>
                      <input
                        type="text"
                        value={companyDetails.annualSales.toLocaleString()}
                        onChange={(e) =>
                          this.handleCompanyInputChange("annualSales", parseInt(e.target.value.replace(/,/g, "")) || 0)
                        }
                      />
                    </InputGroup>
                    <ButtonGroup>
                      <SaveButton onClick={this.handleSaveCompanyDetails}>Save</SaveButton>
                      <Button
                        onClick={() => this.setState({ editingCompany: false })}
                        style={{ backgroundColor: "#ccc" }}
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (

                  <>
                    <p><strong>Industry:</strong> {companyDetails.industry || 'N/A'}</p>
                    <p><strong>Sub-Industry:</strong> {companyDetails.subIndustry || 'N/A'}</p>
                    <p><strong>Employees:</strong> {companyDetails.employees || 'N/A'}</p>
                    <p><strong>Annual Sales:</strong> {companyDetails.annualSales ? `$${companyDetails.annualSales.toLocaleString()}` : 'N/A'}</p>
                    {saveSuccess && <p style={{ color: 'green' }}>Company details saved successfully.</p>}
                    <Button style={{ marginTop: '10px' }} onClick={() => this.setState({ editingCompany: true })}>Edit Company Info</Button>
                  </>
                )}
              </div>

              <h3>Contacts</h3>
              <ContactList>
                {contacts.length > 0 ? (
                  contacts.map((contact) => (
                    <ContactItem key={contact.id}>
                      {editingContactId === contact.id ? (
                        <>
                          <InputGroup>
                            <label>Name:</label>
                            <input
                              type="text"
                              value={contactEdits.sName}
                              onChange={(e) => this.handleInputChange('sName', e.target.value)}
                            />
                          </InputGroup>
                          <InputGroup>
                            <label>Phone:</label>
                            <input
                              type="text"
                              value={contactEdits.sPhone}
                              onChange={(e) => this.handleInputChange('sPhone', e.target.value)}
                            />
                          </InputGroup>
                          <InputGroup>
                            <label>Email:</label>
                            <input
                              type="email"
                              value={contactEdits.Email1}
                              onChange={(e) => this.handleInputChange('Email1', e.target.value)}
                            />
                          </InputGroup>
                          <ButtonGroup>
                            <SaveButton onClick={this.handleSaveClick}>Save</SaveButton>
                            <DeleteButton onClick={this.handleDeleteClick}>Delete</DeleteButton>
                          </ButtonGroup>
                        </>
                      ) : (
                        <>
                          <p><strong>Name:</strong> {contact.sName}</p>
                          <p><strong>Phone:</strong> {contact.sPhone}</p>
                          <p><strong>Email:</strong> {contact.Email1}</p>
                          <EditButton onClick={() => this.handleEditClick(contact.id)}>Edit</EditButton>
                        </>
                      )}
                    </ContactItem>
                  ))
                ) : (
                  <p>No contacts available for this company.</p>
                )}
              </ContactList>

              {!creatingContact && (
                <Button onClick={this.handleCreateContact}>Create Contact</Button>
              )}

              {creatingContact && (
                <>
                  <h3>Create Contact</h3>
                  <InputGroup>
                    <label>Name:</label>
                    <input
                      type="text"
                      value={newContact.name}
                      onChange={(e) => this.handleNewContactChange('name', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Email:</label>
                    <input
                      type="email"
                      value={newContact.email}
                      onChange={(e) => this.handleNewContactChange('email', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Phone:</label>
                    <input
                      type="text"
                      value={newContact.phone}
                      onChange={(e) => this.handleNewContactChange('phone', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Tier</label>
                    <select
                      value={newContact.tier}
                      onChange={(e) => this.handleNewContactChange('tier', e.target.value)}
                    >
                      <option value="3">Tier 3 (Closed Quote)</option>
                      <option value="4">Tier 4 (Discontinued Quote)</option>
                      <option value="5">Tier 5 (???)</option>
                      <option value="6">Tier 6 (Don't Want)</option>
                    </select>
                  </InputGroup>
                  <ButtonGroup>
                    <Button onClick={this.handleSubmitNewContact}>Save Contact</Button>
                    <Button onClick={() => this.setState({ creatingContact: false })} style={{ backgroundColor: '#ccc' }}>Cancel</Button>
                  </ButtonGroup>
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalWrapper>
    );
  }
}

export default CompanyDetailsModal;
