import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';
import { whichRep } from '../../../assets/functions/calculations';
import { sendEmail } from '../../../assets/functions/email';

class DailyMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
        };
    }

    fetchData = async () => {
        try {
          const fetchRFQSent = await getSQLData("rac/rfqSentMetrics", {})
          const fetchRFQWaiting = await getSQLData("rac/quotesWaitingOnRFQ", {})
          const fetchCustomerWaiting = await getSQLData("rac/quotesWaitingOnCustomer", {})
          const fetchResponseWaiting = await getSQLData("rac/quotesWaitingOnResponse", {})
          const fetchAssignedQuotes = await getSQLData("rac/assignedQuotesByRep", {})
          const fetchCreatedQuotes = await getSQLData("rac/createdQuotesByRep", {})
          const fetchSentQuotes = await getSQLData("rac/quotesSentByRep", {})
          const fetchAssignedDayStats = await getSQLData("rac/assignedQuotesDayStats", {})
          const fetchAssignedHourStats = await getSQLData("rac/assignedQuotesHourStats", {})
          const fetchEmail = await getSQLData("email/getUserEmail", {userID: this.props.user})
    
          if(fetchRFQSent && fetchRFQWaiting && fetchCustomerWaiting && fetchResponseWaiting && fetchAssignedQuotes && fetchCreatedQuotes && fetchSentQuotes
             && fetchAssignedDayStats && fetchAssignedHourStats && fetchEmail){
    
            let data = {
                RFQSent: fetchRFQSent.data.data[0].recordset,
                RFQWaiting: fetchRFQWaiting.data.data[0].recordset,
                CustomerWaiting: fetchCustomerWaiting.data.data[0].recordset,
                ResponseWaiting: fetchResponseWaiting.data.data[0].recordset,
                AssignedQuotes: fetchAssignedQuotes.data.data[0].recordset,
                CreatedQuotes: fetchCreatedQuotes.data.data[0].recordset,
                SentQuote: fetchSentQuotes.data.data[0].recordset,
                
            }
            let userEmail = fetchEmail.data.data[0].recordset[0].sEmail

            this.setState({
              data: data,
              userEmail: userEmail,
            }, () => this.processData())
          }
        } catch (error) {
          console.error(error);
        }
      };
    
      componentDidMount(){
        this.fetchData()
      }

    processData() {
        let data = this.state.data
        let reformat = {
            AssignedQuotes: {},
            CreatedQuotes: {},
            SentQuotes: {},
            QuotesWaitingRFQ: {},
            QuotesWaitingCustomer: {},
            QuotesWaitingResponse: {},
            RFQSent: {},
        }
        let reps = {
            8: "Tony",
            22: "Randy",
            51: "Mark",
        }

        for(let i=0; i<data.AssignedQuotes.length; i++){
            let currentRep = data.AssignedQuotes[i].nRep
            if(reps[currentRep]){
                reformat.AssignedQuotes[currentRep] = data.AssignedQuotes[i][""]
            }
        }
        for(let i=0; i<data.CreatedQuotes.length; i++){
            let currentRep = data.CreatedQuotes[i].nRep
            if(reps[currentRep]){
                reformat.CreatedQuotes[currentRep] = data.CreatedQuotes[i][""]
            }
        }
        for(let i=0; i<data.SentQuote.length; i++){
            let currentRep = data.SentQuote[i].nRep
            if(reps[currentRep]){
                reformat.SentQuotes[currentRep] = data.SentQuote[i][""]
            }
        }
        for(let i=0; i<data.RFQWaiting.length; i++){
            let currentRep = data.RFQWaiting[i].nRep
            if(reps[currentRep]){
                reformat.QuotesWaitingRFQ[currentRep] = data.RFQWaiting[i][""]
            }
        }
        for(let i=0; i<data.CustomerWaiting.length; i++){
            let currentRep = data.CustomerWaiting[i].nRep
            if(reps[currentRep]){
                reformat.QuotesWaitingCustomer[currentRep] = data.CustomerWaiting[i][""]
            }
        }
        for(let i=0; i<data.ResponseWaiting.length; i++){
            let currentRep = data.ResponseWaiting[i].nRep
            if(reps[currentRep]){
                reformat.QuotesWaitingResponse[currentRep] = data.ResponseWaiting[i][""]
            }
        }
        for(let i=0; i<data.RFQSent.length; i++){
            let currentRep = data.RFQSent[i].nRep
            if(reps[currentRep]){
                reformat.RFQSent[currentRep] = data.RFQSent[i][""]
            }
        }

        
        for(const metric in reformat){
            reformat[metric].Total = 0
            reformat[metric].Max = 0
            for(const rep in reformat[metric]){
                if(rep !== "Total" && rep !== "Max"){
                    reformat[metric].Total += reformat[metric][rep]

                    if(reformat[metric][rep] > reformat[metric].Max){
                        reformat[metric].Max = reformat[metric][rep]
                    }
                }
            }
        }

        let targets = {
            assignedQuotes: 20,
            createdQuotes: 15,
            sentQuotes: 20,
            sentRFQ: 30,
        }
        
        let userInput = {
            salesInbox: 0,
            groupPhone: 0,
            tawkTo: 0,
            fuCalls: 0,
            fuEmails: 0,
            fuText: 0,
        }

        let metricNames = {
            AssignedQuotes: "Quotes Assigned",
            CreatedQuotes: "Quotes Created",
            SentQuotes: "Quotes Sent",
            QuotesWaitingRFQ: "Quotes Waiting on RFQs",
            QuotesWaitingCustomer: "Quotes Waiting on Customer Info",
            QuotesWaitingResponse: "Quotes Waiting on Yes/No",
            RFQSent: "RFQs Sent",
            salesInbox: "Sales Inbox Emails Handled",
            groupPhone: "Group Phone Calls Handled",
            tawkTo: "Tawk.to Chats Handled",
            fuCalls: "Follow Up Calls Completed",
            fuText: "Follow Up Texts Sent",
            fuEmails: "Follow Up Emails Sent",
        }

        this.setState({
            data: reformat,
            targets: targets,
            userInput: userInput,
            metricNames: metricNames,
            loaded: true,
        })
    }

    updateInput(metric, input){
        let userInput = this.state.userInput
        if(parseInt(input) == input){
            userInput[metric] = parseInt(input)
        }   
        
        this.setState({
            userInput: userInput,
        })
    }

    sendMetrics = async () => {
        let data = this.state.data
        let userInput = this.state.userInput
        let user = this.props.user
        let userEmail = this.state.userEmail
        let metricNames = this.state.metricNames


        let emailBody = "Hi Andy, this is "
        emailBody += whichRep(user)
        emailBody += " and my metrics today are: <br /><table>"
        for(const metric in data){
            let score = data[metric][user] ? data[metric][user] : 0
            emailBody += "<tr><td>" + metricNames[metric] + "</td><td>" + score + "</td></tr>"
        }
        for(const metric in userInput){
            emailBody += "<tr><td>" + metricNames[metric] + "</td><td>" + userInput[metric] + "</td></tr>"
        }
        emailBody += "</table>"

        try {
            const email = await sendEmail(userEmail, "jr@cfrinc.us; andy@cfrinc.us", "Daily Metrics", emailBody)
        } catch {
            
        }
        
    }

    render() {
        let data = this.state.data
        let loaded = this.state.loaded
        let targets = this.state.targets
        let userInput = this.state.userInput
        return (
            <>
            {loaded && (
            
            <div>
                 <table className="dashboard" style={{width: '700px'}}>
                    <thead>
                        <tr>
                            <th style={{width: '300px'}}>Metric (Target)</th>
                            <th style={{width: '100px'}}>Total</th>
                            <th style={{width: '100px'}}>Tony</th>
                            <th style={{width: '100px'}}>Randy</th>
                            <th style={{width: '100px'}}>Mark</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Quotes Picked Up ({targets.assignedQuotes})</td>
                        <td>{data?.AssignedQuotes?.Total || 0} {data?.AssignedQuotes?.Total >= targets.assignedQuotes ? <i class="fa fa-star" /> : ""}</td>                        
                        <td>{data?.AssignedQuotes?.[8] || 0} {(data?.AssignedQuotes?.[8] === data?.AssignedQuotes?.Max && data?.AssignedQuotes?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.AssignedQuotes?.[22] || 0} {(data?.AssignedQuotes?.[22] === data?.AssignedQuotes?.Max && data?.AssignedQuotes?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.AssignedQuotes?.[51] || 0} {(data?.AssignedQuotes?.[51] === data?.AssignedQuotes?.Max && data?.AssignedQuotes?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Quotes Created ({targets.createdQuotes})</td>
                        <td>{data?.CreatedQuotes?.Total || 0} {data?.CreatedQuotes?.Total >= targets.createdQuotes ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.CreatedQuotes?.[8] || 0} {(data?.CreatedQuotes?.[8] === data?.CreatedQuotes?.Max && data?.CreatedQuotes?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.CreatedQuotes?.[22] || 0} {(data?.CreatedQuotes?.[22] === data?.CreatedQuotes?.Max && data?.CreatedQuotes?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.CreatedQuotes?.[51] || 0} {(data?.CreatedQuotes?.[51] === data?.CreatedQuotes?.Max && data?.CreatedQuotes?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Quotes Sent to Customer ({targets.sentQuotes})</td>
                        <td>{data?.SentQuotes?.Total || 0} {data?.SentQuotes?.Total >= targets.sentQuotes ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.SentQuotes?.[8] || 0} {(data?.SentQuotes?.[8] === data?.SentQuotes?.Max && data?.SentQuotes?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.SentQuotes?.[22] || 0} {(data?.SentQuotes?.[22] === data?.SentQuotes?.Max && data?.SentQuotes?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.SentQuotes?.[51] || 0} {(data?.SentQuotes?.[51] === data?.SentQuotes?.Max && data?.SentQuotes?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>RFQs Sent ({targets.sentRFQ})</td>
                        <td>{data?.RFQSent?.Total || 0} {data?.RFQSent?.Total >= targets.sentRFQ ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.RFQSent?.[8] || 0} {(data?.RFQSent?.[8] === data?.RFQSent?.Max && data?.RFQSent?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.RFQSent?.[22] || 0} {(data?.RFQSent?.[22] === data?.RFQSent?.Max && data?.RFQSent?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.RFQSent?.[51] || 0} {(data?.RFQSent?.[51] === data?.RFQSent?.Max && data?.RFQSent?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Quotes Waiting on Customer Info</td>
                        <td>{data?.QuotesWaitingCustomer?.Total || 0}</td>
                        <td>{data?.QuotesWaitingCustomer?.[8] || 0} {(data?.QuotesWaitingCustomer?.[8] === data?.QuotesWaitingCustomer?.Max && data?.QuotesWaitingCustomer?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingCustomer?.[22] || 0} {(data?.QuotesWaitingCustomer?.[22] === data?.QuotesWaitingCustomer?.Max && data?.QuotesWaitingCustomer?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingCustomer?.[51] || 0} {(data?.QuotesWaitingCustomer?.[51] === data?.QuotesWaitingCustomer?.Max && data?.QuotesWaitingCustomer?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Quotes Waiting on RFQs</td>
                        <td>{data?.QuotesWaitingRFQ?.Total || 0}</td>
                        <td>{data?.QuotesWaitingRFQ?.[8] || 0} {(data?.QuotesWaitingRFQ?.[8] === data?.QuotesWaitingRFQ?.Max && data?.QuotesWaitingRFQ?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingRFQ?.[22] || 0} {(data?.QuotesWaitingRFQ?.[22] === data?.QuotesWaitingRFQ?.Max && data?.QuotesWaitingRFQ?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingRFQ?.[51] || 0} {(data?.QuotesWaitingRFQ?.[51] === data?.QuotesWaitingRFQ?.Max && data?.QuotesWaitingRFQ?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Quotes Waiting on Customer Yes/No</td>
                        <td>{data?.QuotesWaitingResponse?.Total || 0}</td>
                        <td>{data?.QuotesWaitingResponse?.[8] || 0} {(data?.QuotesWaitingResponse?.[8] === data?.QuotesWaitingResponse?.Max && data?.QuotesWaitingResponse?.[8] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingResponse?.[22] || 0} {(data?.QuotesWaitingResponse?.[22] === data?.QuotesWaitingResponse?.Max && data?.QuotesWaitingResponse?.[22] > 0) ? <i class="fa fa-star" /> : ""}</td>
                        <td>{data?.QuotesWaitingResponse?.[51] || 0} {(data?.QuotesWaitingResponse?.[51] === data?.QuotesWaitingResponse?.Max && data?.QuotesWaitingResponse?.[51] > 0) ? <i class="fa fa-star" /> : ""}</td>
                    </tr>
                    <tr>
                        <td>Shared Sales Inbox Emails Handled</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("salesInbox", e.target.value)} value={userInput.salesInbox} /></td>
                    </tr>
                    <tr>
                        <td>Group Phone Calls Handled</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("groupPhone", e.target.value)} value={userInput.groupPhone} /></td>
                    </tr>
                    <tr>
                        <td>Tawk.to Chats Handled</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("tawkTo", e.target.value)} value={userInput.tawkTo} /></td>
                    </tr>
                    <tr>
                        <td>Follow Up Calls Made</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("fuCalls", e.target.value)} value={userInput.fuCalls} /></td>
                    </tr>
                    <tr>
                        <td>Follow Up Texts Made</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("fuText", e.target.value)} value={userInput.fuText} /></td>
                    </tr>
                    <tr>
                        <td>Follow Up Emails Made</td>
                        <td colspan={4}><input onChange={(e)=>this.updateInput("fuEmails", e.target.value)} value={userInput.fuEmails} /></td>
                    </tr>
                    <tr>
                        <td colspan={5}><button onClick={()=>this.sendMetrics()}>Submit Daily Metrics</button></td>
                    </tr>
                </table>
                
            </div>
            )}
            </>
        );
    }
}

export default DailyMetrics;