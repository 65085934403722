import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';
import { StyledTable } from './StyledComponents';
import styled from 'styled-components';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

const Wrapper = styled.div`
  padding: 20px;
`;

class QuotesReviewReport extends Component {
  state = {
    reviewedCounts: [],
    tierContactsCounts: []
  };

  componentDidMount() {
    this.fetchReviewCounts();
    this.fetchTierContactCounts();
  }

  fetchReviewCounts = async () => {
    try {
      const response = await getSQLData('logistics/reviewedQuotesCounts');
      if (response?.data?.data[0]) {
        this.setState({ reviewedCounts: response.data.data[0].recordset });
      }
    } catch (error) {
      console.error('Error fetching reviewed quotes counts:', error);
    }
  };

  fetchTierContactCounts = async () => {
    try {
      const response = await getSQLData('logistics/tierContactsAddedCounts');
      if (response?.data?.data[0]) {
        this.setState({ tierContactsCounts: response.data.data[0].recordset });
      }
    } catch (error) {
      console.error('Error fetching tier contacts counts:', error);
    }
  };

  render() {
    const { reviewedCounts, tierContactsCounts } = this.state;

    return (
      <Wrapper>
        <h2>Quotes Review Report</h2>
        <StyledTable>
          <thead>
            <tr>
              <th>Rep Name</th>
              <th>Reviewed in Last 24 Hours</th>
              <th>Reviewed in Last 1 Week</th>
              <th>Reviewed in Last 1 Month</th>
              <th>Tier 3 Contacts Added</th>
              <th>Tier 4 Contacts Added</th>
            </tr>
          </thead>
          <tbody>
            {reviewedCounts.map((review, idx) => {
              const repName = repNames[review.rep_id] || `Rep ID: ${review.rep_id}`;
              const tierCounts = tierContactsCounts.find(tc => tc.rep_id === review.rep_id) || {};

              return (
                <tr key={idx}>
                  <td>{repName}</td>
                  <td>{review.reviewed_24hrs || 0}</td>
                  <td>{review.reviewed_1week || 0}</td>
                  <td>{review.reviewed_1month || 0}</td>
                  <td>{tierCounts.tier_3_contacts_added || 0}</td>
                  <td>{tierCounts.tier_4_contacts_added || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </Wrapper>
    );
  }
}

export default QuotesReviewReport;
