import React, { Component } from 'react';
import { ModalOverlay, ModalContent, ModalHeader, ModalTitle, CloseButton, InputGroup, ButtonContainer, Button, DeleteButton } from '../../tables/marketing-wizard/StyledComponents';
import { setSQLData } from '../../../assets/functions/fetch';

class EditContactModal extends Component {
  state = {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactTier: '3', // Default tier
    showDeleteConfirmation: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.contact && nextProps.contact.contactId !== prevState.contactId) {
      // Update state when the contact prop changes
      return {
        contactId: nextProps.contact.contactId,
        contactName: nextProps.contact.contactName || '',
        contactEmail: nextProps.contact.contactEmail || '',
        contactPhone: nextProps.contact.contactPhone || '',
        contactTier: nextProps.contact.contactTier || '3', // Set tier from props or default to 3
      };
    }
    return null; // No state update needed
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = async () => {
    const { contact, onClose, onDelete } = this.props;

    try {
      const response = await setSQLData('marketing/deleteContact', { contactId: contact.contactId });
      if (response.status === 200) {
        this.setState({ showDeleteConfirmation: false });
        onDelete(contact.contactId);  // Call the parent function to remove the contact
        onClose();  // Close the modal
      } else {
        console.error('Error deleting contact:', response.error);
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  toggleDeleteConfirmation = () => {
    this.setState((prevState) => ({
      showDeleteConfirmation: !prevState.showDeleteConfirmation,
    }));
  };

  handleSubmit = async () => {
    const { contact, onClose } = this.props;
    const { contactName, contactEmail, contactPhone, contactTier } = this.state;
  
    try {
      const response = await setSQLData('marketing/updateContact', {
        id: contact.contactId,  // Ensure the correct ID is passed here
        sName: contactName,
        Email1: contactEmail,
        sPhone: contactPhone,
        sTier: contactTier,  // Send the selected tier to the database
      });
      if (response.status === 200) {
        onClose();  // Close the modal after updating
      } else {
        console.error('Error updating contact:', response.error);
      }
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };
  

  render() {
    const { onClose } = this.props;
    const { contactName, contactEmail, contactPhone, contactTier, showDeleteConfirmation } = this.state;

    if (showDeleteConfirmation) {
      return (
        <ModalOverlay>
          <ModalContent>
            <p>Are you sure you want to delete this contact?</p>
            <ButtonContainer>
              <Button onClick={this.toggleDeleteConfirmation}>Cancel</Button>
              <DeleteButton onClick={this.handleDelete}>Delete</DeleteButton>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      );
    }

    return (
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Edit Contact</ModalTitle>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </ModalHeader>

          <InputGroup>
            <label>Contact Name</label>
            <input
              type="text"
              name="contactName"
              value={contactName}
              onChange={this.handleInputChange}
            />
          </InputGroup>

          <InputGroup>
            <label>Email</label>
            <input
              type="email"
              name="contactEmail"
              value={contactEmail}
              onChange={this.handleInputChange}
            />
          </InputGroup>

          <InputGroup>
            <label>Phone</label>
            <input
              type="text"
              name="contactPhone"
              value={contactPhone}
              onChange={this.handleInputChange}
            />
          </InputGroup>

          {/* New InputGroup for selecting the contact tier */}
          <InputGroup>
            <label>Tier</label>
            <select
              name="contactTier"
              value={contactTier}
              onChange={this.handleInputChange}
            >
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
              <option value="5">Tier 5</option>
              <option value="6">Tier 6</option>
            </select>
          </InputGroup>

          <ButtonContainer>
            <DeleteButton onClick={this.toggleDeleteConfirmation}>Delete</DeleteButton>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={this.handleSubmit}>Save Changes</Button>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    );
  }
}

export default EditContactModal;
