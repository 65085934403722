import React, { useState, useEffect } from 'react';
import { ContactList, ContactItem, ActionButton, EditActionButton } from '../../tables/marketing-wizard/StyledComponents'; // Styled components
import { getSQLData, setSQLData } from '../../../assets/functions/fetch'; // Import setSQLData to send data to the DB
import EditContactModal from './EditContactModal';
import ContactLog from './ContactLog';
import BlastEmailEditor from '../marketing-wizard/BlastEmailEditor';
import styled from 'styled-components'; // Import styled-components

const SuccessPopup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  z-index: 1000;
  animation: fadeOut 4s forwards;

  @keyframes fadeOut {
    0% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const StyledDropdown = styled.select`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const ViewAsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ViewAsLabel = styled.span`
  font-size: 1.1rem;
  margin-right: 15px;
  color: #333;
  font-weight: 500;
`;

const ContactCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ContactHeader = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const SectionTitle = styled.h4`
  margin-bottom: 8px;
  color: #666;
  font-size: 1.1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
`;

const SectionContent = styled.p`
  margin: 5px 0;
  color: #444;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px; /* Added margin-bottom to create space between buttons and blast email editor */
`;

const NavigationButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const BlastEmailButton = styled.button`
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const CollapseButton = styled.button`
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #c82333;
  }
`;

const NoContactButton = styled.button`
  margin-left: 10px;
  padding: 12px 24px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e0a800;
  }
`;

const RepSelector = styled.select`
  margin: 20px;
  padding: 10px;
  font-size: 1rem;
`;

const ContactsList = ({ nRep }) => {
    const [contacts, setContacts] = useState([]);
    const [currentContactIndex, setCurrentContactIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [showBlastEditor, setShowBlastEditor] = useState(false); // State for Blast Email Editor
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [displayAll, setDisplayAll] = useState(false); // State to toggle displaying all contacts
    const [showRepMenu, setShowRepMenu] = useState(false); // Toggle to show rep dropdown
    const [currentRep, setCurrentRep] = useState(nRep); // Current representative
    const [selectedTier, setSelectedTier] = useState('3'); // Track selected tier (3 or 4)
    const [contactCount, setContactCount] = useState(0);  // New state for contact count
    const [allContactCount, setAllContactCount] = useState(0);  // New state for total Tier 3 + Tier 4 contacts

    const reps = [
        { id: 64, name: 'Jeff Bruck' },
        { id: 8, name: 'Tony Cipollone' },
        { id: 51, name: 'Mark Gillaugh' },
        { id: 54, name: 'Brian Kubala' },
        { id: 22, name: 'Randy Moore' },
        { id: 63, name: 'Hunter Stewart' },
        { id: 5, name: 'Andy Wendt' },     
    ];

    const loadContacts = async (repId, tier, isAll = false) => {
        try {
            let response;
            if (isAll) {
                // Fetch both Tier 3 and Tier 4 contacts
                const tier3Response = await getSQLData('marketing/tier3ContactsFiltered', { sRep: repId });
                const tier4Response = await getSQLData('marketing/tier4ContactsFiltered', { sRep: repId });
                const tier3Contacts = tier3Response?.data?.data[0]?.recordset || [];
                const tier4Contacts = tier4Response?.data?.data[0]?.recordset || [];
                const allContacts = [...tier3Contacts, ...tier4Contacts]; // Combine Tier 3 and Tier 4
                setContacts(allContacts);
                setAllContactCount(allContacts.length);  // Set the total number of Tier 3 + Tier 4 contacts
            } else {
                // Fetch only the selected tier contacts
                response = await getSQLData(`marketing/tier${tier}ContactsFiltered`, { sRep: repId });
                if (response && response.data && response.data.data[0]) {
                    const contactsData = response.data.data[0].recordset;
                    setContacts(contactsData);
                    setContactCount(contactsData.length);  // Set the number of contacts for selected tier
                }
            }
        } catch (error) {
            console.error(`Error loading filtered contacts for Tier ${tier}:`, error);
            setContactCount(0);  // In case of error, set contact count to 0
        } finally {
            setLoading(false);
        }
    };

    const logNoContact = async (contact) => {
        try {
            const logData = {
                nRep: currentRep,
                nContact: contact.contactId,
                sType: 'No Contact',            // Set sType to 'No Contact'
                sDisposition: 'No Contact',     // Set sDisposition to 'No Contact'
                sNote: 'User Initiated No Contact' // Set sNote to predefined message
            };

            const response = await setSQLData('marketing/insertContactLog', logData);
            if (response.success) {
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 2000);
            } else {
                console.error('Failed to log No Contact.');
            }
        } catch (error) {
            console.error('Error logging No Contact:', error);
        }
    };

    useEffect(() => {
        loadContacts(currentRep, selectedTier); // Load contacts for the selected rep and tier
    }, [currentRep, selectedTier]);

    // Toggle testing mode with backtick (`) key
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === '`') {
                setShowRepMenu(!showRepMenu); // Toggle the dropdown menu
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [showRepMenu]);

    const openEditModal = () => {
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const openLogModal = () => {
        setShowLogModal(true);
    };

    const closeLogModal = () => {
        setShowLogModal(false);
    };

    const openBlastEmailEditor = () => { // Open Blast Email Editor
        setShowBlastEditor(true);
    };

    const closeBlastEmailEditor = () => { // Close Blast Email Editor
        setShowBlastEditor(false);
    };

    const handleNextContact = () => {
        if (currentContactIndex < contacts.length - 1) {
            setCurrentContactIndex(currentContactIndex + 1);
        }
    };

    const handlePreviousContact = () => {
        if (currentContactIndex > 0) {
            setCurrentContactIndex(currentContactIndex - 1);
        }
    };

    const toggleDisplayAll = () => {
        if (!displayAll) {
            // If toggling on "Display All", load both Tier 3 and Tier 4 contacts
            loadContacts(currentRep, selectedTier, true);
        }
        setDisplayAll(!displayAll);
    };

    const handleTierChange = (e) => {
        setSelectedTier(e.target.value);
    };

    const renderContactLogs = (contactLogs) => {
        if (!contactLogs || contactLogs.trim() === '') return <SectionContent>No previous interactions logged.</SectionContent>;

        const logsList = contactLogs.split(', ');
        return (
            <ul>
                {logsList.map((log, index) => (
                    <li key={index}>{log}</li>
                ))}
            </ul>
        );
    };

    const renderContacts = () => {
        if (contacts.length === 0) return <li>No Tier {selectedTier} contacts found.</li>;

        if (displayAll) {
            return contacts.map((contact, index) => (
                <ContactCard key={contact.contactId}>
                    <ContactHeader>{contact.contactName} - {contact.companyName}</ContactHeader>

                    <Section>
                        <SectionTitle>Contact Information</SectionTitle>
                        <SectionContent>Email: {contact.contactEmail}</SectionContent>
                        <SectionContent>Phone: {contact.contactPhone}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Recent Orders</SectionTitle>
                        {contact.recentOrders ? (
                            <ul>
                                {contact.recentOrders.split(', ').map((order, i) => {
                                    const [quoteId, orderDetails] = order.split('|');
                                    return (
                                        <li key={i}>
                                            <a href={`https://cfrinc.app/#/quote/${quoteId.trim()}`} target="_blank" rel="noopener noreferrer">
                                                Quote #{quoteId.trim()} - {orderDetails}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <SectionContent>No recent orders</SectionContent>
                        )}
                    </Section>

                    <Section>
                        <SectionTitle>Top Item Category</SectionTitle>
                        <SectionContent>{contact.topItemCategory || 'N/A'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Sales in Last 2 Years</SectionTitle>
                        <SectionContent>{contact.salesLast2Years ? `$${contact.salesLast2Years.toLocaleString()}` : '0'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Previous Interactions</SectionTitle>
                        {renderContactLogs(contact.contactLogs)}
                    </Section>

                    <ButtonContainer>
                        <EditActionButton onClick={openEditModal}>Edit</EditActionButton>
                        <ActionButton onClick={openLogModal}>Log Interaction</ActionButton>
                        <NoContactButton onClick={() => logNoContact(contact)}>No Contact</NoContactButton> {/* New No Contact button */}
                    </ButtonContainer>
                </ContactCard>
            ));
        } else {
            const contact = contacts[currentContactIndex];

            return (
                <ContactCard key={contact.contactId}>
                    <ContactHeader>{contact.contactName} - {contact.companyName}</ContactHeader>

                    <Section>
                        <SectionTitle>Contact Information</SectionTitle>
                        <SectionContent>Email: {contact.contactEmail}</SectionContent>
                        <SectionContent>Phone: {contact.contactPhone}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Recent Orders</SectionTitle>
                        {contact.recentOrders ? (
                            <ul>
                                {contact.recentOrders.split(', ').map((order, i) => {
                                    const [quoteId, orderDetails] = order.split('|');
                                    return (
                                        <li key={i}>
                                            <a href={`https://cfrinc.app/#/quote/${quoteId.trim()}`} target="_blank" rel="noopener noreferrer">
                                                Quote #{quoteId.trim()} - {orderDetails}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <SectionContent>No recent orders</SectionContent>
                        )}
                    </Section>

                    <Section>
                        <SectionTitle>Top Item Category</SectionTitle>
                        <SectionContent>{contact.topItemCategory || 'N/A'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Sales in Last 2 Years</SectionTitle>
                        <SectionContent>{contact.salesLast2Years ? `$${contact.salesLast2Years.toLocaleString()}` : '0'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Previous Interactions</SectionTitle>
                        {renderContactLogs(contact.contactLogs)}
                    </Section>

                    <ButtonContainer>
                        <EditActionButton onClick={openEditModal}>Edit</EditActionButton>
                        <ActionButton onClick={openLogModal}>Log Interaction</ActionButton>
                        <NoContactButton onClick={() => logNoContact(contact)}>No Contact</NoContactButton> {/* New No Contact button */}
                    </ButtonContainer>
                </ContactCard>
            );
        }
    };

    if (loading) {
        return <div>Loading contacts...</div>;
    }

    return (
        <div>
            <h2>Tier {selectedTier} Marketing Contacts ({displayAll ? allContactCount : contactCount}) - Viewing as: {reps.find(rep => rep.id === parseInt(currentRep))?.name}</h2>

            {/* Display rep selector with label when the menu is toggled */}
            {showRepMenu && (
                <ViewAsContainer>
                    <ViewAsLabel>View As:</ViewAsLabel>
                    <StyledDropdown value={currentRep} onChange={(e) => setCurrentRep(e.target.value)}>
                        {reps.map(rep => (
                            <option key={rep.id} value={rep.id}>
                                {rep.name}
                            </option>
                        ))}
                    </StyledDropdown>
                </ViewAsContainer>
            )}

            {/* Tier selection dropdown */}
            <ViewAsContainer>
                <ViewAsLabel>Select Tier:</ViewAsLabel>
                <StyledDropdown value={selectedTier} onChange={handleTierChange}>
                    <option value="3">Tier 3 (Closed Orders)</option>
                    <option value="4">Tier 4 (Discontinued Orders)</option>
                </StyledDropdown>
            </ViewAsContainer>

            {showSuccessMessage && (
                <SuccessPopup>Contact logged successfully!</SuccessPopup>
            )}

            <ContactList>
                {renderContacts()}
            </ContactList>

            <ButtonContainer>
    
                <NavigationButton onClick={toggleDisplayAll}>
                    {displayAll ? `Collapse All (${allContactCount})` : `Display All (${allContactCount})`}
                </NavigationButton>
            </ButtonContainer>

            {showEditModal && contacts.length > 0 && (
                <EditContactModal
                    contact={contacts[currentContactIndex]}
                    onClose={closeEditModal}
                    onSave={() => loadContacts(currentRep, selectedTier)} // Reload contacts after saving
                />
            )}

            {showLogModal && contacts.length > 0 && (
                <ContactLog
                    contactId={contacts[currentContactIndex].contactId}
                    repId={contacts[currentContactIndex].sRep}
                    onClose={closeLogModal}
                    onLogSuccess={() => {
                        setShowSuccessMessage(true);
                        setTimeout(() => setShowSuccessMessage(false), 2000);
                    }}
                />
            )}
        </div>
    );
};

export default ContactsList;
