module.exports = {
    marketing_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        racCustomerQuoteData: {
            query: "SELECT rcq.nCompany, "
                + "(SELECT SUM(rcq2.nQuoteTotal) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany AND rcq2.sStatus='Closed') AS Sales, "
                + "(SELECT MAX(rcq2.dtFirstClosed) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany AND rcq2.sStatus='Closed') AS LastSale, "
                + "(SELECT MAX(rcq2.dtLastUpdated) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany) AS LastUpdate, "
                + "(SELECT TOP 1 rcq2.sCompany FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany ORDER BY id desc) AS Company, "
                + "(SELECT TOP 1 rcq2.sName FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany ORDER BY id desc) AS Contact, "
                + "(SELECT TOP 1 rcq2.sPhone FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany ORDER BY id desc) AS Phone, "
                + "(SELECT TOP 1 rcq2.sEmail FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany ORDER BY id desc) AS Email, "
                + "(SELECT TOP 1 rcq2.nRep FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany = rcq.nCompany ORDER BY id desc) AS Rep "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sQuoteType='Rentacomputer.com' AND rcq.dtDateStamp >= DATEADD(year,-3,GETDATE()) AND rcq.bDeleted <> 1 AND rcq.nCompany > 0 "
                + "GROUP BY nCompany",
            req: [],
            fields: [
                {key: "nCompany", label: "Company ID"},
                {key: "Company", label: "Company Name"},
                {key: "Contact", label: "Contact Name"},
                {key: "Phone", label: "Phone"},
                {key: "Email", label: "Email"},
                {key: "Sales", label: "Total Sales"},
                {key: "LastSale", label: "Date of Last Sale"},
                {key: "LastUpdate", label: "Date of Last Update"},
                {key: "Rep", label: "Rep"},
            ],
            desc: "Returns Company Data",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        racOpenQuotes: {
            query: "SELECT DISTINCT sCompany, sName, sEmail FROM RAC_CUSTOMER_QUOTES WHERE sStatus='Open' AND sQuoteType='Rentacomputer.com' AND bDeleted <> 1",
            req: [],
            fields: [
                {key: "sCompany", label: "Company"},
                {key: "sName", label: "Contact Name"},
                {key: "sEmail", label: "Email"},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        racCustomers3Y: {
            query: "SELECT DISTINCT sCompany, sName, sEmail, nRep "
                + "FROM RAC_CUSTOMER_QUOTES "
                + "WHERE sStatus='Closed' AND dtFirstClosed >= DATEADD(year,-3,GETDATE()) AND sQuoteType='Rentacomputer.com' AND bDeleted <> 1",
            req: [],
            fields: [
                {key: "sCompany", label: "Company"},
                {key: "sName", label: "Contact Name"},
                {key: "sEmail", label: "Email"},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        racLastWeekQuotes: {
            query: "SELECT DISTINCT sCompany, sName, sEmail "
                + "FROM RAC_CUSTOMER_QUOTES "
                + "WHERE dtDateStamp >= DATEADD(week,-1,GETDATE()) AND sQuoteType='Rentacomputer.com' AND bDeleted <> 1",
            req: [],
            fields: [
                {key: "sCompany", label: "Company"},
                {key: "sName", label: "Contact Name"},
                {key: "sEmail", label: "Email"},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        racDiscontinuedQuotes: {
            query: "SELECT DISTINCT sCompany, sName, sEmail "
                + "FROM RAC_CUSTOMER_QUOTES "
                + "WHERE dtDateStamp >= DATEADD(year,-3,GETDATE()) AND dtDateStamp >= DATEADD(month,-11,GETDATE()) "
                + "AND sStatus='Discontinued' AND sQuoteType='Rentacomputer.com' AND bDeleted <> 1",
            req: [],
            fields: [
                {key: "sCompany", label: "Company"},
                {key: "sName", label: "Contact Name"},
                {key: "sEmail", label: "Email"},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        phoneNumbersByCompany: {
            query: "SELECT con.sName, cp.sPhone, con.id AS contactID, cp.id AS phoneID "
                + "FROM COMPANIES com "
                + "LEFT JOIN CONTACTS con ON com.id = con.nCompany "
                + "LEFT JOIN CONTACT_PHONES cp ON con.id = cp.nContact "
                + "WHERE com.id=[customerID]",
            req: ["customerID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all contacts and emails associated with a company.",
            tables: ["COMPANIES", "CONTACTS", "CONTACT_EMAILS"],
            type: "SELECT",
            whereUsed: [],
        },
        emailsByCompany: {
            query: "SELECT con.sName, ce.sEmail, con.id AS contactID, ce.id AS emailID "
                + "FROM COMPANIES com "
                + "LEFT JOIN CONTACTS con ON com.id = con.nCompany "
                + "LEFT JOIN CONTACT_EMAILS ce ON con.id = ce.nContact "
                + "WHERE com.id=[customerID]",
            req: ["customerID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all contacts and emails associated with a company.",
            tables: ["COMPANIES", "CONTACTS", "CONTACT_EMAILS"],
            type: "SELECT",
            whereUsed: [],
        },
        quotesByCustomer: {
            query: "SELECT * FROM RAC_CUSTOMER_QUOTES WHERE nCompany=[customerID] ORDER BY dtFirstClosed desc",
            req: ["customerID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all quotes for a specific customer",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        noMarketingProfile: {
            query: "SELECT TOP 10 c.id, c.sName, rcq.dtFirstClosed, rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN COMPANIES c "
                + "ON rcq.nCompany = c.id "
                + "WHERE rcq.sStatus='Closed' AND rcq.sQuoteType='Rentacomputer.com' "
                + "AND c.dtLastUpdate IS NULL AND rcq.dtFirstClosed <= GETDATE() "
                + "ORDER BY rcq.dtFirstClosed desc",
            req: [],
            fields: [
                {key: "id", label: "Company ID"},
                {key: "sName", label: "Company Name"},
                {key: "dtFirstClosed", label: "Quote first closed date"},
            ],
            desc: "Gets companies who recently closed quotes but don't have marketing profiles. ",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        marketingProfile: {
            query: "SELECT TOP 10 c.id, c.sName, c.sIndustry, c.sSubIndustry, c.nEmployees, c.nCompanyAnnualSales "
                + "FROM COMPANIES c "
                + "WHERE c.dtLastUpdate > '1/1/2024' "
                + "ORDER BY c.dtLastUpdate desc",
            req: [],
            fields: [
                {key: "id", label: "Company ID"},
                {key: "sName", label: "Company Name"},
                {key: "sIndustry", label: "Industry"},
                {key: "sSubIndustry", label: "Sub-Industry"},
                {key: "nEmployees", label: "Employees"},
                {key: "nCompanyAnnualSales", label: "Company Annual Sales"},
            ],
            desc: "Gets companies with most recently updated marketing profiles. ",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        unnamedQuery: {
            query: "SELECT rcq.id, rcq.sCompany, rcq.sName, rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, "
                + "rcq.sDCompany, rcq.sDName, rcq.sDAddress, rcq.sDAddress2, rcq.sDCity, rcq.sDState, rcq.sDZip, "
                + ""
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN QUOTE_MARKETING_DATA qmd on rcq.id=qmd.nQuote "
                + "LEFT JOIN COMPANIES c on rcq.nCompany = c.id ",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        createMarketingQuoteRecords: {
            query: "INSERT INTO QUOTE_MARKETING_DATA (nQuote) " +
            "SELECT rcq.id " +
            "FROM RAC_CUSTOMER_QUOTES rcq " +
            "WHERE rcq.dtFirstClosed >= DATEADD(MONTH, -1, GETDATE()) " +
            "AND rcq.id NOT IN (SELECT nQuote FROM QUOTE_MARKETING_DATA)",
            req: [],
            fields: [],
            desc: "Creates new marketing data logs for yesterday's quotes if they don't already exist.",
            tables: ["RAC_CUSTOMER_QUOTES", "QUOTE_MARKETING_DATA"],
            type: "INSERT",
            whereUsed: [],
        },
        initializeMarketingQuoteRecords: {
            query: "INSERT INTO QUOTE_MARKETING_DATA (nQuote) " +
            "SELECT rcq.id " +
            "FROM RAC_CUSTOMER_QUOTES rcq " +
            "WHERE rcq.id NOT IN (SELECT nQuote FROM QUOTE_MARKETING_DATA)",
            req: [],
            fields: [],
            desc: "Initializes marketing data logs for all quotes if they don't already exist.",
            tables: ["RAC_CUSTOMER_QUOTES", "QUOTE_MARKETING_DATA"],
            type: "INSERT",
            whereUsed: [],
        },
        updateCustomerDealsHistory: {
            query: "UPDATE COMPANIES c " +
            "SET c.nTotalDeals = (" +
            "    SELECT COUNT(rcq.id)" +
            "    FROM RAC_CUSTOMER_QUOTES rcq" +
            "    WHERE rcq.nCompany = c.id" +
            "      AND rcq.sStatus = 'Closed'" +
            "), " +
            "c.nOpenDeals = (" +
            "    SELECT COUNT(rcq.id)" +
            "    FROM RAC_CUSTOMER_QUOTES rcq" +
            "    WHERE rcq.nCompany = c.id" +
            "      AND rcq.sStatus = 'Open'" +
            "), " +
            "c.dtLastDeal = (" +
            "    SELECT MAX(rcq.dtFirstClosed)" +
            "    FROM RAC_CUSTOMER_QUOTES rcq" +
            "    WHERE rcq.nCompany = c.id" +
            ");",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates Companies with total # of deals, open deals, date of last deal.",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES"],
            type: "UPDATE",
            whereUsed: [],
        },
        updateCustomerSalesHistory: {
            query: "UPDATE COMPANIES c " +
            "SET c.nLast1YearSales = (" +
            "    SELECT SUM(rcq1y.QuoteLineTotal + rcq1y.nDelivery) " +
            "    FROM RAC_CUSTOMER_QUOTES rcq1y " +
            "    WHERE rcq1y.nCompany = c.id " +
            "      AND rcq1y.sStatus = 'Closed' " +
            "      AND rcq1y.dtFirstClosed >= DATEADD(YEAR, -1, GETDATE()) " +
            "), " +
            "c.nLast3YearSales = (" +
            "    SELECT SUM(rcq3y.QuoteLineTotal + rcq3y.nDelivery) " +
            "    FROM RAC_CUSTOMER_QUOTES rcq3y " +
            "    WHERE rcq3y.nCompany = c.id " +
            "      AND rcq3y.sStatus = 'Closed' " +
            "      AND rcq3y.dtFirstClosed >= DATEADD(YEAR, -3, GETDATE()) " +
            "), " +
            "c.nLast5YearSales = (" +
            "    SELECT SUM(rcq5y.QuoteLineTotal + rcq5y.nDelivery) " +
            "    FROM RAC_CUSTOMER_QUOTES rcq5y " +
            "    WHERE rcq5y.nCompany = c.id " +
            "      AND rcq5y.sStatus = 'Closed' " +
            "      AND rcq5y.dtFirstClosed >= DATEADD(YEAR, -5, GETDATE()) " +
            ") " +
            "WHERE c.id IN ( " +
            "    SELECT nCompany " +
            "    FROM RAC_CUSTOMER_QUOTES " +
            "    WHERE sStatus = 'Closed' " +
            "    GROUP BY nCompany " +
            "    HAVING COUNT(*) = 1 " +
            "    AND MAX(dtFirstClosed) >= DATEADD(YEAR, -5, GETDATE()) " +
            "); ",
            req: [],
            fields: [],
            desc: "Updates Company data for last 1, 3, and 5 year sales.",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES"],
            type: "UPDATE",
            whereUsed: [],
        },
        updateQuoteLineTotal: {
            query: "UPDATE rcq "
                + "SET rcq.QuoteLineTotal = ("
                + "SELECT SUM(rql.nPrice * rql.nQuantity) "
                + "FROM RAC_QUOTE_LINES rql "
                + "WHERE rcq.id = rql.nQuote) "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtLastUpdated >= DATEADD(WEEK,-1,GETDATE())",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates QuoteLineTotal with the sum of price * quantity for each quote that has been updated in the last day.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_QUOTE_LINES"],
            type: "UPDATE",
            whereUsed: [],
        },
        updateQuotePOTotal: {
            query: "UPDATE rcq "
                + "SET rcq.QuotePOTotal = ("
                + "SELECT SUM(rp.nPOTotal) "
                + "FROM RAC_POS rp "
                + "WHERE rcq.id = rp.nAttachedQuote) "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtLastUpdated >= DATEADD(WEEK,-1,GETDATE())",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates QuotePOTotal with the sum of nPOTotal for each quote that has been updated in the last day.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_POS"],
            type: "UPDATE",
            whereUsed: [],
        },
        
        closedQuotesDateRange: {
            query: "SELECT id, dtDateStamp, sCompany, nQuoteTotal, sName, sEmail FROM RAC_CUSTOMER_QUOTES " +
            "WHERE sStatus = 'Closed' AND dtDateStamp >= '[startDate] 00:00' AND dtDateStamp <= '[endDate] 23:59' AND sQuoteType = 'Rentacomputer.com'",
            req: ["startDate", "endDate"],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "dtDateStamp", label: "Quote Creation Date"},
                {key: "sCompany", label: "Company Name"},
                {key: "nQuoteTotal", label: "Quote Total Price"},
                {key: "sName", label: "Contact Name"},
                {key: "sEmail", label: "Email"},
            ],
            desc: "Lists all quotes closed with a quote creation date between a date range determined by startDate and endDate params.  For WebFX monthly export.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tutorials/Tutorial2",
            ],
        },
        getIndustries: {
            query: "SELECT DISTINCT sIndustry FROM COMPANIES WHERE sIndustry IS NOT NULL ORDER BY sIndustry desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Lists all industries currently used in the Companies table.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        getSubIndustries: {
            query: "SELECT DISTINCT sSubIndustry FROM COMPANIES WHERE sIndustry = '[sIndustry]' ORDER BY sSubIndustry desc",
            req: ["sIndustry"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Lists all sub-industries currently used in the Companies table for a certain industry.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        getCustomerData: {
            query: "SELECT c.* FROM COMPANIES c WHERE c.id=[id]",
            req: ["id"], // Use `id` as the parameter to match the column in the COMPANIES table
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all company data for a single company.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        
        
        setMarketingProfileData: {
            query: "UPDATE COMPANIES SET [fieldName]=[fieldValue], dtLastUpdate=GETDATE() WHERE id=[companyID]",
            req: ["fieldName", "fieldValue", "companyID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a given field in the Companies table",
            tables: ["COMPANIES"],
            type: "UPDATE",
            whereUsed: [],
        },
        tier3ContactsData: {
            query: "SELECT TOP 100 c.* FROM CONTACTS c WHERE c.sTier = '3'",
            req: [],
            fields: [
              {key: "id", label: "Contact ID"},
              {key: "nCompany", label: "Company ID"},
              {key: "sName", label: "Contact Name"},
              {key: "sPhone", label: "Phone"},
              {key: "sEmail", label: "Email"},
              {key: "sTier", label: "Tier"},
              {key: "nRep", label: "Rep"},
            ],
            desc: "Returns Tier 3 contacts",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: [],
          },

          tier3ContactsFiltered: {
            query: `
                SELECT TOP 100 
                    c.id AS contactId,
                    c.sName AS contactName,
                    c.Email1 AS contactEmail,
                    c.sPhone AS contactPhone,
                    c.sTier AS contactTier,
                    c.sRep AS contactRep,
                    comp.id AS companyId,
                    comp.sName AS companyName,
                    -- Recent Orders as a concatenated string (using dtPickupDate instead of dtDeliveryDate)
                    (SELECT STUFF((
                        SELECT ', ' + CONCAT(CAST(id AS NVARCHAR), '|', FORMAT(nQuoteTotal, 'C'), ' - ', FORMAT(dtPickupDate, 'MM/dd/yyyy'))
                        FROM (SELECT TOP 5 id, nQuoteTotal, dtPickupDate
                              FROM RAC_CUSTOMER_QUOTES
                              WHERE nCompany = c.nCompany
                              AND sName = c.sName  -- Match specific contact
                              AND sStatus = 'Closed'
                              ORDER BY dtPickupDate DESC) AS innerQuotes
                        FOR XML PATH(''), TYPE).value('.', 'NVARCHAR(MAX)'), 1, 2, '')) AS recentOrders,
                    -- Top Item Category for Contact
                    (SELECT TOP 1 ic.sName
                     FROM RAC_CUSTOMER_QUOTES
                     JOIN RAC_QUOTE_LINES ql ON RAC_CUSTOMER_QUOTES.id = ql.nQuote
                     JOIN ITEMS i ON ql.nItem = i.id
                     JOIN ITEM_CATEGORIES ic ON i.nCat = ic.id
                     WHERE RAC_CUSTOMER_QUOTES.nCompany = c.nCompany
                     AND RAC_CUSTOMER_QUOTES.sName = c.sName  -- Match specific contact
                     AND RAC_CUSTOMER_QUOTES.sStatus = 'Closed'
                     GROUP BY ic.sName
                     ORDER BY SUM(ql.nQuantity * ql.nPrice) DESC) AS topItemCategory,
                    -- Sales in Last 2 Years for Contact
                    (SELECT SUM(ql.nQuantity * ql.nPrice)
                     FROM RAC_QUOTE_LINES ql
                     JOIN RAC_CUSTOMER_QUOTES ON ql.nQuote = RAC_CUSTOMER_QUOTES.id
                     WHERE RAC_CUSTOMER_QUOTES.nCompany = c.nCompany
                     AND RAC_CUSTOMER_QUOTES.sName = c.sName  -- Match specific contact
                     AND RAC_CUSTOMER_QUOTES.dtPickupDate >= DATEADD(year, -2, GETDATE())
                     AND RAC_CUSTOMER_QUOTES.sStatus = 'Closed') AS salesLast2Years,
                    -- Marketing Contact Logs for Contact
                    (SELECT STUFF((
                        SELECT ', ' + CONCAT(FORMAT(cl.dtDate, 'MM/dd/yyyy'), ' - ', cl.sType, ' - ', cl.sDisposition, ' - ', cl.sNote)
                        FROM CONTACT_LOG cl
                        WHERE cl.nContact = c.id
                        FOR XML PATH(''), TYPE).value('.', 'NVARCHAR(MAX)'), 1, 2, '')) AS contactLogs
                FROM CONTACTS c
                LEFT JOIN COMPANIES comp ON c.nCompany = comp.id
                -- Only display contacts with no contact attempted in the last 6 weeks
                WHERE c.sTier = '3'
                AND c.sRep = '[sRep]'
                AND NOT EXISTS (
                    SELECT 1 FROM CONTACT_LOG cl
                    WHERE cl.nContact = c.id
                    AND cl.dtDate >= DATEADD(week, -6, GETDATE())
                )
            `,
            req: ["sRep"],
            fields: [
                { key: "contactId", label: "Contact ID" },
                { key: "contactName", label: "Contact Name" },
                { key: "contactEmail", label: "Contact Email" },
                { key: "contactPhone", label: "Contact Phone" },
                { key: "contactTier", label: "Tier" },
                { key: "contactRep", label: "Representative" },
                { key: "companyId", label: "Company ID" },
                { key: "companyName", label: "Company Name" },
                { key: "recentOrders", label: "Recent Orders" },
                { key: "topItemCategory", label: "Top Item Category" },
                { key: "salesLast2Years", label: "Sales Last 2 Years" },
                { key: "contactLogs", label: "Contact Logs" }
            ],
            desc: "Lists Tier 3 contacts where no marketing contact has been attempted in the last 6 weeks, including recent orders (showing quote number and pickup date), top item category, and sales in the last 2 years.",
            tables: ["CONTACTS", "COMPANIES", "RAC_CUSTOMER_QUOTES", "RAC_QUOTE_LINES", "ITEMS", "ITEM_CATEGORIES", "CONTACT_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
              
        tier4ContactsFiltered: {
            query: `
                SELECT TOP 100 
                    c.id AS contactId,
                    c.sName AS contactName,
                    c.Email1 AS contactEmail,
                    c.sPhone AS contactPhone,
                    c.sTier AS contactTier,
                    c.sRep AS contactRep,
                    comp.id AS companyId,
                    comp.sName AS companyName,
                    -- Recent Orders as a concatenated string (using dtPickupDate instead of dtDeliveryDate)
                    (SELECT STUFF((
                        SELECT ', ' + CONCAT(CAST(id AS NVARCHAR), '|', FORMAT(nQuoteTotal, 'C'), ' - ', FORMAT(dtPickupDate, 'MM/dd/yyyy'))
                        FROM (SELECT TOP 5 id, nQuoteTotal, dtPickupDate
                              FROM RAC_CUSTOMER_QUOTES
                              WHERE nCompany = c.nCompany
                              AND sName = c.sName  -- Match specific contact
                              AND sStatus = 'Closed'
                              ORDER BY dtPickupDate DESC) AS innerQuotes
                        FOR XML PATH(''), TYPE).value('.', 'NVARCHAR(MAX)'), 1, 2, '')) AS recentOrders,
                    -- Top Item Category for Contact
                    (SELECT TOP 1 ic.sName
                     FROM RAC_CUSTOMER_QUOTES
                     JOIN RAC_QUOTE_LINES ql ON RAC_CUSTOMER_QUOTES.id = ql.nQuote
                     JOIN ITEMS i ON ql.nItem = i.id
                     JOIN ITEM_CATEGORIES ic ON i.nCat = ic.id
                     WHERE RAC_CUSTOMER_QUOTES.nCompany = c.nCompany
                     AND RAC_CUSTOMER_QUOTES.sName = c.sName  -- Match specific contact
                     AND RAC_CUSTOMER_QUOTES.sStatus = 'Closed'
                     GROUP BY ic.sName
                     ORDER BY SUM(ql.nQuantity * ql.nPrice) DESC) AS topItemCategory,
                    -- Sales in Last 2 Years for Contact
                    (SELECT SUM(ql.nQuantity * ql.nPrice)
                     FROM RAC_QUOTE_LINES ql
                     JOIN RAC_CUSTOMER_QUOTES ON ql.nQuote = RAC_CUSTOMER_QUOTES.id
                     WHERE RAC_CUSTOMER_QUOTES.nCompany = c.nCompany
                     AND RAC_CUSTOMER_QUOTES.sName = c.sName  -- Match specific contact
                     AND RAC_CUSTOMER_QUOTES.dtPickupDate >= DATEADD(year, -2, GETDATE())
                     AND RAC_CUSTOMER_QUOTES.sStatus = 'Closed') AS salesLast2Years,
                    -- Marketing Contact Logs for Contact
                    (SELECT STUFF((
                        SELECT ', ' + CONCAT(FORMAT(cl.dtDate, 'MM/dd/yyyy'), ' - ', cl.sType, ' - ', cl.sDisposition, ' - ', cl.sNote)
                        FROM CONTACT_LOG cl
                        WHERE cl.nContact = c.id
                        FOR XML PATH(''), TYPE).value('.', 'NVARCHAR(MAX)'), 1, 2, '')) AS contactLogs
                FROM CONTACTS c
                LEFT JOIN COMPANIES comp ON c.nCompany = comp.id
                -- Only display contacts with no contact attempted in the last 12 weeks
                WHERE c.sTier = '4'
                AND c.sRep = '[sRep]'
                AND NOT EXISTS (
                    SELECT 1 FROM CONTACT_LOG cl
                    WHERE cl.nContact = c.id
                    AND cl.dtDate >= DATEADD(week, -12, GETDATE())
                )
            `,
            req: ["sRep"],
            fields: [
                { key: "contactId", label: "Contact ID" },
                { key: "contactName", label: "Contact Name" },
                { key: "contactEmail", label: "Contact Email" },
                { key: "contactPhone", label: "Contact Phone" },
                { key: "contactTier", label: "Tier" },
                { key: "contactRep", label: "Representative" },
                { key: "companyId", label: "Company ID" },
                { key: "companyName", label: "Company Name" },
                { key: "recentOrders", label: "Recent Orders" },
                { key: "topItemCategory", label: "Top Item Category" },
                { key: "salesLast2Years", label: "Sales Last 2 Years" },
                { key: "contactLogs", label: "Contact Logs" }
            ],
            desc: "Lists Tier 4 contacts where no marketing contact has been attempted in the last 12 weeks, including recent orders (showing quote number and pickup date), top item category, and sales in the last 2 years.",
            tables: ["CONTACTS", "COMPANIES", "RAC_CUSTOMER_QUOTES", "RAC_QUOTE_LINES", "ITEMS", "ITEM_CATEGORIES", "CONTACT_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
         

        
        setCompanyProfileData: {
            query: `UPDATE COMPANIES
                    SET sIndustry = '[sIndustry]', 
                        sSubIndustry = '[sSubIndustry]', 
                        nEmployees = [nEmployees], 
                        nCompanyAnnualSales = [nCompanyAnnualSales]
                    WHERE id = [companyId]`,
            req: ["companyId", "sIndustry", "sSubIndustry", "nEmployees", "nCompanyAnnualSales"],
            fields: [
                { key: "companyId", label: "Company ID" },
                { key: "sIndustry", label: "Industry" },
                { key: "sSubIndustry", label: "Sub-Industry" },
                { key: "nEmployees", label: "Employees" },
                { key: "nCompanyAnnualSales", label: "Annual Sales" },
            ],
            desc: "Updates company profile information in the COMPANIES table.",
            tables: ["COMPANIES"],
            type: "UPDATE",
        },
        recentQuotesData: {
            query: "SELECT TOP 10 rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nCompany = [nCompany] "
                + "ORDER BY rcq.id DESC",
            req: ["nCompany"],
            fields: [
                {key: "id", label: "Quote ID"},
                {key: "nCompany", label: "Company ID"},
                {key: "nQuoteTotal", label: "Quote Total"},
                {key: "sStatus", label: "Status"},
                {key: "dtDateStamp", label: "Date Stamp"},
                {key: "dtFirstClosed", label: "First Closed Date"},
                {key: "dtLastUpdated", label: "Last Updated Date"},
            ],
            desc: "Returns the most recent quotes for a company",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        insertContactLog: {
            query: "INSERT INTO CONTACT_LOG "
                + "(dtDate, nRep, nContact, sType, sDisposition, sNote) "
                + "VALUES (GETDATE(), [nRep], [nContact], '[sType]', '[sDisposition]', '[sNote]')",
            req: ["nRep", "nContact", "sType", "sDisposition", "sNote"],
            fields: [],
            desc: "Inserts a new contact log entry",
            tables: ["CONTACT_LOG"],
            type: "INSERT",
            whereUsed: [],
        },
        tier4ContactsData: {
            query: "SELECT TOP 1 c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany NOT IN ( "
                + "SELECT rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Open' "
                + "OR (rcq.sStatus='Discontinued' AND rcq.dtLastUpdated >= DATEADD(week, -12, GETDATE())) "
                + "OR (rcq.sStatus='Closed' AND rcq.dtPickupDate >= DATEADD(week, -12, GETDATE())) "
                + ") "
                + "AND c.nRep = [nRep] "
                + "AND c.id NOT IN ( "
                + "SELECT mcl.nContact "
                + "FROM CONTACT_LOG mcl "
                + "WHERE mcl.dtDate >= DATEADD(week, -12, GETDATE()) "
                + ") "
                + "AND c.sTier = '4'",
            req: [],
            fields: [
                {key: "id", label: "Contact ID"},
                {key: "nCompany", label: "Company ID"},
                {key: "sName", label: "Contact Name"},
                {key: "sPhone", label: "Phone"},
                {key: "sEmail", label: "Email"},
                {key: "sTier", label: "Tier"},
                {key: "nRep", label: "Rep"},
            ],
            desc: "Returns Tier 4 contacts that meet specified conditions",
            tables: ["CONTACTS", "RAC_CUSTOMER_QUOTES", "CONTACT_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        marketingEffortsData: {
            query: `
                SELECT COUNT(c.id) AS Tier3Contacts, 
                       (SELECT SUM(rcq.nQuoteTotal) 
                        FROM RAC_CUSTOMER_QUOTES rcq 
                        WHERE rcq.nCompany IN 
                            (SELECT DISTINCT c2.nCompany 
                             FROM CONTACTS c2 
                             WHERE c2.nRep = c.nRep 
                             AND c2.sTier = '3') 
                        AND rcq.dtFirstClosed >= DATEADD(year, -1, GETDATE())) AS Tier3Sales,
                       (SELECT COUNT(c2.id) 
                        FROM CONTACTS c2 
                        WHERE c2.nRep = c.nRep 
                        AND c2.sTier = '3' 
                        AND c2.dtDateAdded <= DATEADD(week, -1, GETDATE())) AS NewTier3Contacts
                FROM CONTACTS c 
                WHERE c.sTier = '3'
                GROUP BY c.nRep
            `,
            req: ["nRep"],
            fields: [
                {key: "nRep", label: "Rep"},
                {key: "Tier3Contacts", label: "Tier 3 Contacts"},
                {key: "Tier3Sales", label: "Sales from Tier 3 Contacts"},
                {key: "NewTier3Contacts", label: "New Tier 3 Contacts"},
            ],
            desc: "Summarizes marketing efforts for Tier 3 contacts",
            tables: ["CONTACTS", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        
        updateContact: {
            query: `
              UPDATE CONTACTS
              SET sName = '[sName]', 
                  nCompany = [nCompany], 
                  Email1 = '[Email1]', 
                  Email2 = '[Email2]', 
                  Email3 = '[Email3]', 
                  sPhone = '[sPhone]', 
                  sRep = [sRep], 
                  sMainBrand = '[sMainBrand]', 
                  sTier = '[sTier]' 
              WHERE id = [id]`,
            req: ["id", "sName", "nCompany", "Email1", "Email2", "Email3", "sPhone", "sRep", "sMainBrand", "sTier"],
            fields: [
              { key: "id", label: "Contact ID" },
              { key: "sName", label: "Contact Name" },
              { key: "nCompany", label: "Company ID" },
              { key: "Email1", label: "Email 1" },
              { key: "Email2", label: "Email 2" },
              { key: "Email3", label: "Email 3" },
              { key: "sPhone", label: "Phone" },
              { key: "sRep", label: "Rep ID" },
              { key: "sMainBrand", label: "Main Brand" },
              { key: "sTier", label: "Tier" }
            ],
            desc: "Updates an existing contact with the provided details.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: ["components/QuoteManager.js"],
          },
          
        getContactDetails: {
            query: `
              SELECT 
                id, 
                sName, 
                nCompany, 
                Email1, 
                Email2, 
                Email3, 
                sPhone, 
                sRep, 
                sMainBrand, 
                sTier 
              FROM CONTACTS 
              WHERE id = [id]
            `,
            req: ["id"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Email 1" },
                { key: "Email2", label: "Email 2" },
                { key: "Email3", label: "Email 3" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Fetches the details of a specific contact by ID.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["EditContactModal.js"],
        },
        searchContacts: {
            query: `
              SELECT
                c.id,
                c.sName,
                c.nCompany,
                c.Email1,
                c.Email2,
                c.Email3,
                c.sPhone,
                c.sRep,
                c.sMainBrand,
                c.sTier,
                LOWER(c.sName) AS lowerName,
                LOWER(c.Email1) AS lowerEmail1
              FROM CONTACTS c
              WHERE LOWER(c.sName) = LOWER('[searchString]')
                OR LOWER(c.Email1) = LOWER('[searchString]')
                OR LOWER(c.Email2) = LOWER('[searchString]')
                OR LOWER(c.Email3) = LOWER('[searchString]')
                OR LOWER((SELECT com.sName FROM COMPANIES com WHERE com.id = c.nCompany)) = LOWER('[searchString]')
              UNION ALL
              SELECT
                c.id,
                c.sName,
                c.nCompany,
                c.Email1,
                c.Email2,
                c.Email3,
                c.sPhone,
                c.sRep,
                c.sMainBrand,
                c.sTier,
                LOWER(c.sName) AS lowerName,
                LOWER(c.Email1) AS lowerEmail1
              FROM CONTACTS c
              WHERE LOWER(c.sName) LIKE LOWER('%[searchString]%')
                OR LOWER(c.Email1) LIKE LOWER('%[searchString]%')
                OR LOWER(c.Email2) LIKE LOWER('%[searchString]%')
                OR LOWER(c.Email3) LIKE LOWER('%[searchString]%')
                OR LOWER((SELECT com.sName FROM COMPANIES com WHERE com.id = c.nCompany)) LIKE LOWER('%[searchString]%')
              ORDER BY lowerName, lowerEmail1
            `,
            req: ["searchString"],
            fields: [
              { key: "id", label: "Contact ID" },
              { key: "sName", label: "Contact Name" },
              { key: "nCompany", label: "Company ID" },
              { key: "Email1", label: "Email 1" },
              { key: "Email2", label: "Email 2" },
              { key: "Email3", label: "Email 3" },
              { key: "sPhone", label: "Phone" },
              { key: "sRep", label: "Rep ID" },
              { key: "sMainBrand", label: "Main Brand" },
              { key: "sTier", label: "Tier" }
            ],
            desc: "Searches for contacts by name, email, or company name. Prioritizes exact matches and then shows partial matches.",
            tables: ["CONTACTS", "COMPANIES"],
            type: "SELECT",
            whereUsed: ["MarketingSearch.js"],
        },
        deleteContact: {
            query: "DELETE FROM CONTACTS "
              + "WHERE id = [id]",
            req: ["id"],
            fields: [
              { key: "id", label: "Contact ID" },
            ],
            desc: "Deletes a contact with the provided ID.",
            tables: ["CONTACTS"],
            type: "DELETE",
            whereUsed: ["components/CreateContactModal.js"],
        },
        tierContactsSummary: {
            query: `
                SELECT 
                    c.nRep AS repId,
                    c.sRep AS repName,
                    COUNT(CASE WHEN c.sTier = '3' THEN 1 END) AS tier3Contacts,
                    COUNT(CASE WHEN c.sTier = '4' THEN 1 END) AS tier4Contacts,
                    COUNT(c.id) AS totalContacts
                FROM CONTACTS c
                GROUP BY c.nRep, c.sRep
            `,
            fields: [
                { key: "repId", label: "Rep ID" },
                { key: "repName", label: "Rep Name" },
                { key: "tier3Contacts", label: "Tier 3 Contacts" },
                { key: "tier4Contacts", label: "Tier 4 Contacts" },
                { key: "totalContacts", label: "Total Contacts" }
            ],
            desc: "Summarizes Tier 3/4 contacts by sales rep using CONTACTS table",
            tables: ["CONTACTS"],
            type: "SELECT"
        },

        contactLogsSummary: {
            query: `
                DECLARE @period NVARCHAR(50)
                SET @period = '[period]'
        
                SELECT 
                    cl.nRep AS loggedRepId,  -- The rep ID logged in CONTACT_LOG
                    c.sRep AS contactRepId,  -- The rep ID in CONTACTS (sales representative ID)
                    COALESCE(cl.nRep, c.sRep) AS repId,  -- Use the COALESCE to prioritize cl.nRep
                    COALESCE(c.sRep, 'Unknown Rep') AS repName,  -- Fall back to 'Unknown Rep' if no sRep found
                    COUNT(CASE WHEN cl.sType = 'Phone' THEN 1 END) AS phoneCalls,
                    COUNT(CASE WHEN cl.sType = 'Email' THEN 1 END) AS emails,
                    COUNT(CASE WHEN cl.sDisposition = 'No Contact' THEN 1 END) AS noContact
                FROM CONTACT_LOG cl
                LEFT JOIN CONTACTS c ON cl.nContact = c.id  -- Join based on nContact to get the correct contact's representative
                WHERE cl.dtDate >= 
                    CASE 
                        WHEN @period = '24 hours' THEN DATEADD(day, -1, GETDATE()) 
                        WHEN @period = '1 week' THEN DATEADD(week, -1, GETDATE()) 
                        ELSE DATEADD(week, -6, GETDATE())
                    END
                GROUP BY cl.nRep, c.sRep
            `,
            req: ["period"],
            fields: [
                { key: "loggedRepId", label: "Logged Rep ID" },  // Add loggedRepId to see what's coming from CONTACT_LOG
                { key: "contactRepId", label: "Contact Rep ID" },  // Add contactRepId to see the rep from CONTACTS
                { key: "repId", label: "Rep ID" },
                { key: "repName", label: "Rep Name" },
                { key: "phoneCalls", label: "Phone Calls" },
                { key: "emails", label: "Emails" },
                { key: "noContact", label: "No Contact" }
            ],
            desc: "Summarizes phone, email, and 'No Contact' logs by rep based on selected time period",
            tables: ["CONTACT_LOG", "CONTACTS"],
            type: "SELECT"
        },
        
        
            
    }

} 