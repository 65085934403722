import React, { Component } from 'react';
import styled from 'styled-components';
import QuoteLinesPane from './QuoteLinesPane';
import Billing from './Billing';
import AdditionalInformation from './AdditionalInformation';
import QuoteLinesBreakdown from './QuoteLinesBreakdown';
import PopUp from './PopUp';

const Top = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export default class QuoteLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewing: "",
      viewRecord: 0,
    };

  }

  render() {
    console.log(this.props)
    const viewing = this.state.viewing
    const viewRecord = this.state.viewRecord
    return (
      <div>
        <PopUp
          viewing={viewing}
          viewRecord={viewRecord}
        />
        <Top>
          <Billing
            dtBillingCycleStart={this.props.data.dtBillingCycleStart}
            dtBillingCycleEnd={this.props.data.dtBillingCycleEnd}
            sRentalPeriod={this.props.data.sRentalPeriod}
            nInsurance={this.props.data.nInsurance}
          />
          <AdditionalInformation
            nContractType={this.props.data.nContractType}
            nDeliveryType={this.props.data.nDeliveryType}
            bCC={this.props.data.bCC}
            bNet={this.props.data.bNet}
            bCOD={this.props.data.bCOD}
          />
        </Top>
        <QuoteLinesBreakdown
          POs={this.props.POs}
          quoteLines={this.props.quoteLines}
          nDelivery={this.props.data.nDelivery}
          
        />
        <QuoteLinesPane
          quoteLines={this.props.quoteLines}
          nDelivery={this.props.data.nDelivery}
          nTaxRate={this.props.data.nTaxRate}
          handleClickQuoteLine={this.props.handleClickQuoteLine}
        />
      </div>
    );
  }
}
