import React, { Component } from 'react';
import styled from 'styled-components';
import Items from '../../../assets/json/items.json';
import { NewLineButton, Wrapper } from '../../styled/ui';
import QuoteLine from './QuoteLine';
import QuoteTotalSection from './QuoteTotalSection';



export default class QuoteLinesPane extends Component {
  render() {
    
    let nSubTotal = 0;
    const quoteLines = this.props.quoteLines
    const nDelivery = this.props.nDelivery
    const nTaxRate = this.props.nTaxRate

    // Loops through quoteLines and calculates the subtotal
    for (let i = 0; i < quoteLines.length; i++) {
      let nItemTotal = quoteLines[i].nPrice * quoteLines[i].nQuantity;
      nSubTotal += nItemTotal;
    }

    // Calculates Total amount of Tax
    let nTax = (nSubTotal + nDelivery) * (nTaxRate / 100);

    return (
      <Wrapper>
        <table style={{ width: '100%' }} className="dashboard">
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Quantity</th>
              <th style={{ width: '20%' }}>Item</th>
              <th style={{ width: '55%' }}>Equipment Description</th>
              <th style={{ width: '5%' }}>Unit Price</th>
              <th style={{ width: '5%' }}>Total</th>
              <th style={{ width: '5%' }}>PO Cost</th>
              <th style={{ width: '5%' }}>Margin</th>
            </tr>
          </thead>
          <tbody>
            {quoteLines.map((sql,index) => (
              <QuoteLine
                sql = {sql} 
                index = {index}
                handleClickQuoteLine={this.props.handleClickQuoteLine}
              />                          
            ))}
            <tr>
              <NewLineButton colSpan="5">New Line</NewLineButton>
            </tr>
            <QuoteTotalSection
              nSubTotal={nSubTotal}
              nDelivery={nDelivery}
              nTax={nTax}
              nTaxRate={nTaxRate}
            />
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
