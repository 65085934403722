import Axios from 'axios';

export async function sendEmail (from, to, subject, text) {
    const email = {
        from: from,
        to: to,
        subject: subject,
        html: text,
    }
    try {
        const response = await Axios.post("/send-email", {email: email})

        
        if(response){
            console.log(response)
        }
    } catch (error) {
        
    }
}

