export function calcQuoteLinePOCost(quoteLines, quotePOLines){
    for(let i=0; i<quoteLines.length; i++){
        quoteLines[i].POCost = 0
    }
    for(let i=0 ; i<quotePOLines.length; i++){
        if(quotePOLines[i].nQuoteLine){
          let POLine = quotePOLines[i]
          let line = quoteLines.find((quoteLine) => quoteLine.id === POLine.nQuoteLine)
          line.POCost = line.POCost ? line.POCost + POLine.nPrice * POLine.nQuantity : POLine.nPrice * POLine.nQuantity
        }
      }
    for(let i=0; i<quoteLines.length; i++){
        quoteLines[i].POCost = (quoteLines[i].POCost ? quoteLines[i].POCost : 0)
        quoteLines[i].Margin = quoteLines[i].nQuantity*quoteLines[i].nPrice - quoteLines[i].POCost
    }

    return quoteLines
}

export function returnUnassignedPOLines(quotePOLines){
    let unassignedPOLines = []
    for(let i=0 ; i<quotePOLines.length; i++){
        if(!quotePOLines[i].nQuoteLine){
            quotePOLines[i].index = i
            unassignedPOLines.push(quotePOLines[i])
        }
    }
    return unassignedPOLines
}

export function returnAssignedPOLines(quotePOLines, quoteLine){
    let assignedPOLines = []
    if(quotePOLines && quoteLine){
        for(let i=0; i<quotePOLines.length; i++){
            let POLine = quotePOLines[i]
            if(POLine.nQuoteLine === quoteLine.id){
                quotePOLines[i].index = i
                assignedPOLines.push(POLine)
            }
        }
    }
    
    return assignedPOLines
}