export function returnPotentialRange(potential){
    switch (potential){
        case 1:
            return "< $250"
            break;
        case 2:
            return "$250 - $999"
            break;
        case 3:
            return "$1,000 - $4,999"
            break;
        case 4:
            return "$5,000 - $9,999"
            break;
        case 5:
            return "> $10,000"
            break;
        default:
            return "NR"
    }
}
export function returnLikelihood(Likelihood){
    switch (Likelihood){
        case 1:
            return "None / Very Low"
            break;
        case 2:
            return "Low"
            break;
        case 3:
            return "Average"
            break;
        case 4:
            return "High"
            break;
        case 5:
            return "Near Certain"
            break;
        default:
            return "NR"
    }
}

export function matchQuotesAndPOs(data){
    let quotes = data.quotes
    let POs = data.POs

    for(let i=0; i<quotes.length; i++){
        let quote = quotes[i]
        quote.POs = []
        for(let j=0; j<POs.length; j++){
            let PO = POs[j]

            if(quote.id === PO.nAttachedQuote){
                quote.POs.push(PO)
            }
        }
    }

    return data
}
export function matchQuotesAndFollowups(data){
    let quotes = data.quotes
    let followups = data.followups

    for(let i=0; i<quotes.length; i++){
        let quote = quotes[i]
        quote.followups = []
        for(let j=0; j<followups.length; j++){
            let followup = followups[j]

            if(quote.id === followup.nQuote){
                quote.followups.push(followup)
            }
        }
    }

    return data
}
export function matchQuotesAndQuoteLines(data){
    let quotes = data.quotes
    let quoteLines = data.quoteLines

    for(let i=0; i<quotes.length; i++){
        let quote = quotes[i]
        quote.quoteLines = []
        for(let j=0; j<quoteLines.length; j++){
            let quoteLine = quoteLines[j]

            if(quote.id === quoteLine.nQuote){
                quote.quoteLines.push(quoteLine)
            }
        }
    }

    return data
}
export function matchQuotesAndNotesv2(data){
    let quotes = data.quotes
    let notes = data.notes

    for(let i=0; i<quotes.length; i++){
        let quote = quotes[i]
        quote.notes = []
        for(let j=0; j<notes.length; j++){
            let note = notes[j]

            if(quote.id === note.nQuote){
                quote.notes.push(note)
            }
        }
    }



    return data
}
export function matchQuotesAndRFQs(data){
    let quotes = data.quotes
    let RFQs = data.RFQs

    for(let i=0; i<quotes.length; i++){
        let quote = quotes[i]
        quote.RFQs = []
        for(let j=0; j<RFQs.length; j++){
            let RFQ = RFQs[j]

            if(quote.id === RFQ.nQuote){
                quote.RFQs.push(RFQ)
            }
        }
    }

    return data
}