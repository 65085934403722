import React, { useEffect, useState } from 'react';
import { StyledTable, Spinner, StyledDropdown } from '../../tables/marketing-wizard/StyledComponents';
import { getSQLData } from '../../../assets/functions/fetch';

// Representative Data
const reps = [
    { id: 64, name: 'Jeff Bruck' },
    { id: 8, name: 'Tony Cipollone' },
    { id: 51, name: 'Mark Gillaugh' },
    { id: 54, name: 'Brian Kubala' },
    { id: 22, name: 'Randy Moore' },
    { id: 63, name: 'Hunter Stewart' },
    { id: 5, name: 'Andy Wendt' }
];

// Function to map the repName (sRep from CONTACTS) to the correct representative name
const getRepName = (sRep) => {
    const rep = reps.find(rep => rep.id.toString() === sRep);
    return rep ? rep.name : `Unknown Rep (ID: ${sRep})`;
};

const MarketingEffortsSummary = () => {
    const [summary, setSummary] = useState([]);
    const [tierSummary, setTierSummary] = useState([]); // State for Tier 3/4 contacts summary
    const [logSummary, setLogSummary] = useState([]); // State for Phone/Email/No Contact summary
    const [loading, setLoading] = useState(true);
    const [timePeriod, setTimePeriod] = useState('1 week'); // Default time period

    // Load the marketing efforts data
    const loadMarketingEfforts = async () => {
        try {
            const response = await getSQLData('marketing/marketingEffortsData', { numContacts: 10 });
            if (response && response.data && response.data.data[0]) {
                setSummary(response.data.data[0].recordset);
            }
        } catch (error) {
            console.error('Error fetching marketing efforts:', error);
        }
    };

    // Load the Tier 3/4 contacts summary data
    const loadTierSummary = async () => {
        try {
            const response = await getSQLData('marketing/tierContactsSummary');
            if (response && response.data && response.data.data[0]) {
                setTierSummary(response.data.data[0].recordset);
                console.log("Tier Contacts Summary: ", response.data.data[0].recordset); // Added for troubleshooting
            }
        } catch (error) {
            console.error('Error fetching Tier summary:', error);
        }
    };

    // Load the Phone/Email/No Contact logs summary data
    const loadLogSummary = async (period) => {
        try {
            const response = await getSQLData('marketing/contactLogsSummary', { period });
            if (response && response.data && response.data.data[0]) {
                setLogSummary(response.data.data[0].recordset);
            }
        } catch (error) {
            console.error('Error fetching log summary:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        loadMarketingEfforts();
        loadTierSummary();
        loadLogSummary(timePeriod); // Load with the default time period
        setLoading(false);
    }, [timePeriod]); // Reload when the time period changes

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <h2>Marketing Efforts Summary</h2>
            
            {/* Table for Tier 3/4 Contacts Summary */}
            <h3>Tier 3/4 Contacts by Sales Rep</h3>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Rep</th>
                        <th># Tier 3 Contacts</th>
                        <th># Tier 4 Contacts</th>
                        <th>Total Contacts</th>
                    </tr>
                </thead>
                <tbody>
                    {tierSummary.length > 0 ? (
                        tierSummary.map((entry, index) => (
                            <tr key={index}>
                                <td>{getRepName(entry.repName)}</td>  {/* Map the correct name */}
                                <td>{entry.tier3Contacts}</td>
                                <td>{entry.tier4Contacts}</td>
                                <td>{entry.totalContacts}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No contact summary found.</td>
                        </tr>
                    )}
                </tbody>
            </StyledTable>

            {/* Time Period Dropdown */}
            <div style={{ margin: '20px 0' }}>
                <label htmlFor="timePeriod">Select Time Period: </label>
                <StyledDropdown
                    id="timePeriod"
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                >
                    <option value="24 hours">Last 24 Hours</option>
                    <option value="1 week">Last 1 Week</option>
                    <option value="6 weeks">Last 6 Weeks</option>
                </StyledDropdown>
            </div>

            {/* Table for Phone/Email/No Contact Logs Summary */}
            <h3>Contact Logs Summary by Sales Rep ({timePeriod})</h3>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Rep</th>
                        <th>Phone Calls</th>
                        <th>Emails</th>
                        <th>No Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {logSummary.length > 0 ? (
                        logSummary.map((entry, index) => (
                            <tr key={index}>
                                <td>{getRepName(entry.repName)}</td>  {/* Map the correct name */}
                                <td>{entry.phoneCalls}</td>
                                <td>{entry.emails}</td>
                                <td>{entry.noContact}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No contact log data found for {timePeriod}.</td>
                        </tr>
                    )}
                </tbody>
            </StyledTable>
        </div>
    );
};

export default MarketingEffortsSummary;
