import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import MediaQuery from 'react-responsive';
import Loader from 'react-loader-spinner';
import { calcPreTaxMargin, nQuoteTotalFormatter, nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { weekStart } from '../../../assets/functions/time';
import { Bar } from 'react-chartjs-2';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { Title, Wrapper } from '../../styled/ui';
import { matchClosedAndCompleteToMonths, matchClosedAndCompleteToWeeks, matchQuotesAndLines, matchQuotesAndPOLines, organizeChartData, summarizeMonthsAndYears } from '../../../assets/functions/process/cam';
import moment from 'moment';
import RecentlyCompletedMonthTable from './RecentlyCompleted/RecentlyCompletedMonthTable';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import MonthSummaryTable from './RecentlyCompleted/MonthSummaryTable';
import YearSummaryTable from './RecentlyCompleted/YearSummaryTable';
import RecentlyCompletedChart from './RecentlyCompleted/RecentlyCompletedChart';
import CamMarginChart from './RecentlyCompleted/CamMarginChart';
import RecentlyCompletedWeekTable from './RecentlyCompleted/RecentlyCompletedWeekTable';
import { percentTwoDecimal } from '../../../assets/functions/display';
import { faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons';
import MonthProjectionsTable from './RecentlyCompleted/MonthProjectionsTable';

export default class RecentlyCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      numMonths: 25,
      selectedWeek: 1,
      showChart: "Margin",
      summaryData: {},
    };
    this.handleShowPO = this.handleShowPO.bind(this)
    this.handleSetLabor = this.handleSetLabor.bind(this)
    this.handleHideShow = this.handleHideShow.bind(this)
    this.changeSelectedWeek = this.changeSelectedWeek.bind(this)
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/recentlyCompleted", {numMonths: this.state.numMonths})
      const fetchPOs = await getSQLData("cam/recentlyCompletedPOs", {numMonths: this.state.numMonths})
      const fetchQuoteLines = await getSQLData("cam/recentlyCompletedLines", {numMonths: this.state.numMonths})
      
      if(fetch && fetchPOs && fetchQuoteLines){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query

        let POData = fetchPOs.data.data[0].recordset
        let POQuery = fetchPOs.query

        let quoteLines = fetchQuoteLines.data.data[0].recordset
        
        
        this.setState({
          data: data,
          query: query,
          POData: POData,
          POQuery: POQuery,
          quoteLines: quoteLines,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {

    let data = matchQuotesAndPOLines(this.state.data, this.state.POData)
    data = matchQuotesAndLines(data, this.state.quoteLines)
    let summaryData = matchClosedAndCompleteToWeeks(data)
    console.log(data)

    let months = []
    let lastYear = []
    for(let i=0; i<12; i++){
      months[i] = {
        marginComplete: 0,
        marginProjected: 0,
        revenueComplete: 0,
        revenueProjected: 0,
        laborComplete: 0,
        laborProjected: 0,
      }
      lastYear[i] = {
        marginComplete: 0,
        marginProjected: 0,
        revenueComplete: 0,
        revenueProjected: 0,
        laborComplete: 0,
        laborProjected: 0,
      }
    }

    for(let i=0; i<data.length; i++){
      let quote = data[i]
      if(quote.dtComplete){
        if(moment(quote.dtComplete).format("YYYY") == 2025){
          let month = moment(quote.dtComplete).format("MM")
          months[month-1].marginComplete += quote.TotalPrice - quote.POTotal
          months[month-1].revenueComplete += quote.TotalPrice
          months[month-1].laborComplete += quote.Labor
        }
        if(moment(quote.dtComplete).format("YYYY") == 2024){
          let month = moment(quote.dtComplete).format("MM")
          lastYear[month-1].marginComplete += quote.TotalPrice - quote.POTotal
          lastYear[month-1].revenueComplete += quote.TotalPrice
          lastYear[month-1].laborComplete += quote.Labor
        }
      } else {
        if(quote.dtTargetComplete){
          if(moment(quote.dtTargetComplete).format("YYYY") == 2025){
            let month = moment(quote.dtTargetComplete).format("MM")
            months[month-1].marginProjected += quote.TotalPrice - quote.POTotal
            months[month-1].revenueProjected += quote.TotalPrice
            months[month-1].laborProjected += quote.Labor
          }
        }
      }
    }
    let total = {
      marginComplete: 0,
      marginProjected: 0,
      revenueComplete: 0,
      revenueProjected: 0,
      laborComplete: 0,
      laborProjected: 0,
    }
    let lastYearTotal = {
      marginComplete: 0,
      marginProjected: 0,
      revenueComplete: 0,
      revenueProjected: 0,
      laborComplete: 0,
      laborProjected: 0,
    }
    for(let i=0; i<months.length; i++){
      total.marginComplete += months[i].marginComplete
      total.marginProjected += months[i].marginProjected
      total.revenueComplete += months[i].revenueComplete
      total.revenueProjected += months[i].revenueProjected
      total.laborComplete += months[i].laborComplete
      total.laborProjected += months[i].laborProjected

      lastYearTotal.marginComplete += lastYear[i].marginComplete
      lastYearTotal.marginProjected += lastYear[i].marginProjected
      lastYearTotal.revenueComplete += lastYear[i].revenueComplete
      lastYearTotal.revenueProjected += lastYear[i].revenueProjected
      lastYearTotal.laborComplete += lastYear[i].laborComplete
      lastYearTotal.laborProjected += lastYear[i].laborProjected
    }
    
    this.setState({
      data: data,
      summaryData: summaryData,
      months: months,
      total: total,
      lastYear: lastYear,
      lastYearTotal: lastYearTotal,
      loaded: true,
  })
  }

  componentDidMount() {
    this.fetchData();
  }

  handleShowPO(index){
    let summaryData = this.state.summaryData
    let weekQuotes = summaryData.weekQuotes[this.state.selectedWeek]
    
    weekQuotes[index].showPOs = !weekQuotes[index].showPOs
    this.setState({summaryData: summaryData})
  }

  handleSetLabor = async (lineID, itemNum) => {
    console.log(lineID, itemNum)
    let newValue = (itemNum === 832 ? null : 832)

    try {
      const fetch = await setSQLData("cam/setPOLineItem", {
        lineID: lineID,
        itemNum: newValue,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }
  handleHideShow(index){
    let hideMonth = this.state.hideMonth
    hideMonth[index] = !hideMonth[index]
    this.setState({hideMonth: hideMonth})
  }

  changeSelectedWeek(week){
    this.setState({selectedWeek: week})
  }

  render() {
      let summaryData = this.state.summaryData
      let selectedWeek = this.state.selectedWeek
      let selectedWeekSales = summaryData.sales ? summaryData.sales[selectedWeek] : 0
      let selectedWeekTargetSales = summaryData.targetSales ? summaryData.targetSales[selectedWeek] : 0
      let selectedWeekAverageSales = summaryData.movingAverageSales ? summaryData.movingAverageSales[selectedWeek] : 0
      let selectedWeekMargin = summaryData.margin ? summaryData.margin[selectedWeek] : 0
      let selectedWeekTargetMargin = summaryData.targetMargin ? summaryData.targetMargin[selectedWeek] : 0
      let selectedWeekAverageMargin = summaryData.movingAverageMargin ? summaryData.movingAverageMargin[selectedWeek] : 0
      let selectedWeekLabor = summaryData.labor ? summaryData.labor[selectedWeek] : 0
      let selectedWeekTargetLabor = summaryData.targetLabor ? summaryData.targetLabor[selectedWeek] : 0
      let selectedWeekAverageLabor = summaryData.movingAverageLabor ? summaryData.movingAverageLabor[selectedWeek] : 0
      let months = this.state.months
      let total = this.state.total
      let lastYear = this.state.lastYear
      let lastYearTotal = this.state.lastYearTotal
    
    return (
      <>
      {this.state.loaded && (
        <Wrapper>
          <div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Margin" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Margin"})}>Margin</div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Sales" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Sales"})}>Sales</div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Labor" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Labor"})}>Labor</div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Projections" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Projections"})}>Projections</div>
          </div>
          
          <div>
          {this.state.showChart === "Margin" && (
            <CamMarginChart 
              type={"Margin"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.margin.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageMargin.slice(0,52).reverse()}
              target={this.state.summaryData.targetMargin.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundMargin}
              lastYearMargin={this.state.summaryData.movingAverageMargin.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          {this.state.showChart === "Sales" && (
            <CamMarginChart 
              type={"Sales"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.sales.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageSales.slice(0,52).reverse()}
              target={this.state.summaryData.targetSales.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundSales}
              lastYearMargin={this.state.summaryData.movingAverageSales.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          {this.state.showChart === "Labor" && (
            <CamMarginChart 
              type={"Labor"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.labor.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageLabor.slice(0,52).reverse()}
              target={this.state.summaryData.targetLabor.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundLabor}
              lastYearMargin={this.state.summaryData.movingAverageLabor.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          {this.state.showChart === "Projections" && (
            <div style={{width:'500px', display: 'inline-block'}}>
              <MonthProjectionsTable 
                months= {months}
                lastYear= {lastYear}
                total= {total}
                lastYearTotal= {lastYearTotal}
              />
            
            </div>
          )}
          </div>
          {this.state.showChart !== "Projections" && (
            <RecentlyCompletedWeekTable
              quotes={this.state.summaryData.weekQuotes[this.state.selectedWeek]}
              weekIndex={this.state.selectedWeek}
              handleShowPO={this.handleShowPO}
              handleSetLabor={this.handleSetLabor}
            />
          )}




      
        </Wrapper>
      )}
      </>
      
    );
  }
}
