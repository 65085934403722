import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';
import { numberOfDays } from '../../../../assets/functions/display';
import { returnSiteVisitArrayName } from '../../../../assets/functions/process/cam';


export default class SiteVisitTablev2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let viewing = this.props.viewing
    let data = this.props.data
    let arrayName = returnSiteVisitArrayName(viewing)
    return (
      <>
      <Title>{viewing}</Title>
      {data[arrayName].length > 0 && (
        <div>
          {data[arrayName].map((quote, index) => (
            <table key={quote.id} style={{border: '1px solid black', borderRadius: '5px 5px 5px 5px'}}>
              <tr>
                <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                  <div style={{fontWeight: 'bold', fontSize: 16}}><Link target="_blank" to={`/quote/${quote.id}`}>Quote {quote.id} <br /> {quote.sQuoteTitle}</Link></div>
                  <div>{quote.sCompany}</div>
                  <div>{quote.sCity}, {quote.sState} {quote.sZip}</div>
                  <div>{quote.sName}</div>
                </td>
                <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                  <div><b>Price</b>: {nQuoteTotalNoDollar(quote.nQuoteTotal)}</div>
                  <div><b>Age</b>: {numberOfDays(quote.Age)} old</div>
                  <div><b>Rep</b>: {quote.nRep ? whichRep(quote.nRep) : null}</div>
                  <div><b>Type</b>: {quote.sType} {quote.sCampaign ? quote.sCampaign : ""}</div>
                  <div><b>Status</b>: {quote.sStatus}</div>           
                </td>
                <td>
                  <button onClick={()=>this.props.handleRemoveFromList(quote.id)}>Discontinue Site Visit</button>
                </td> 
                <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Site Visit Planned</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSiteVisitPlanned')}} type="date" id="planned" name="planned" 
                          value={moment(quote.dtSiteVisitPlanned).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(quote.dtSiteVisitPlanned,quote.id,"dtSiteVisitPlanned")}>Submit</SubmitText>
                      </div>
                    </td>
                    <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Site Visit Date</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSiteVisit')}} type="date" id="actual" name="actual"
                          value={moment(quote.dtSiteVisit).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(quote.dtSiteVisit,quote.id,"dtSiteVisit")}>Submit</SubmitText>
                      </div>
                    </td>
                    <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Quote Sent Date</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSentDate')}} type="date" id="sent" name="sent"
                          value={moment(quote.dtSentDate).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(quote.dtSentDate,quote.id,"dtSentDate")}>Submit</SubmitText>
                      </div>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{verticalAlign: 'top', textAlign:'left'}}>
                        <div><b>Notes</b></div>
                            {quote.notes.map((note, index)=>(
                            <>
                            {index < 6 && (
                              <div style={{margin: '10px'}}>
                                <div><b>{whichRep(note.nRep)} {moment(note.dtTimeStamp).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
                                <div>{note.sNote}</div>
                              </div>
                            )}
                            </>
                          ))}

                    </td>        
                    <td colSpan={3} style={{verticalAlign: 'top', textAlign:'left'}}>
                        <div><b>Followups</b></div>
                            {quote.followups.map((followup, index)=>(
                            <>
                            {(followup.dtCompleted || !followup.bActive) || (
                              <div style={{margin: '10px'}}>
                                <div><b>{whichRep(followup.nRep)} {moment(followup.dtDate).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
                                <div>{followup.sNote}</div>
                              </div>
                            )}
                            </>
                          ))}

                    </td>        
      
                    
                    </tr>
            </table>
          ))}
        </div>
      )}
          
      </>
    );
  }
}