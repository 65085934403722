module.exports = {
    quote_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        updateQuoteLine: {
            query: "UPDATE RAC_QUOTE_LINES "
                + "SET "
                + "bTax = [bTax], "
                + "nItem = [nItem], "
                + "nPO = [nPO], "
                + "nPrice = [nPrice], "
                + "nQuantity = [nQuantity], "
                + "nSort = [nSort], "
                + "nSort = [nSort] "
                + "WHERE id = [id]",
            req: ["bTax","nItem","nPO","nPrice","nQuantity","nSort","id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a quote line.",
            tables: ["RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: [],
        },
        assignPOLine: {
            query: "UPDATE RAC_PO_LINES SET nQuoteLine=[quoteLine] WHERE id=[POLine]",
            req: ["quoteLine", "POLine"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        createNote: {
            query: "INSERT INTO RAC_QUOTE_NOTES "
                + "(nQuote, sNote, nRep, nType, dtTimeStamp, nAttention, nAttentionPriority) " 
                + "VALUES ("
                + "[nQuote],"
                + "'[sNote]',"
                + "[nRep],"
                + "1, GETDATE(), "
                + "[nAttention], "
                +"0)",
            req: ["nQuote", "sNote", "nRep", "nAttention"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        bestAvailableQuote: {
            query: "SELECT id, sName, sCompany, sAddress, sCity, sState, sZip, sPhone, sEmail, "
                + "sQuote, sType, dtDateStamp, sQuoteType, dtDeliveryDate, dtPickupDate, "
                + "sReferringSite, sTimeZone, sLeadSource, nPotential "
                + "FROM RAC_CUSTOMER_QUOTES "
                + "WHERE nRep IS NULL AND sStatus='Open' AND NOT bDeleted=1 "
                + "AND sQuoteType='[sQuoteType]' "
                + "ORDER BY nPotential desc, dtDateStamp asc",
            req: ["sQuoteType"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets the id of the oldest quote with the highest potential.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        setPaidStatus: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET nPaid = [paidStatus] WHERE id = [quoteNum] ",
            req: ["paidStatus", "quoteNum"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Sets paid status on a quote",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/pages/Quote"],
        },
        quoteData: {
            query: "SELECT rcq.*, rr.sName AS RepName, rr.sInitials as RepInitials, rr.sPhones AS RepPhone, rr.sEmail AS RepEmail, c.sQBCustomer "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN RAC_REPS rr on rcq.nRep = rr.id "
                + "LEFT JOIN COMPANIES c on rcq.nCompany = c.id "
                + "WHERE rcq.id=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about a quote using [quoteID]",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_REPS"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteLines: {
            query: "SELECT rql.*, i.sQBItem AS Item "
                + "FROM RAC_QUOTE_LINES rql "
                + "LEFT JOIN ITEMS i ON rql.nItem = i.id "
                + "WHERE rql.nQuote=[quoteID] "
                + "ORDER BY rql.nSort asc",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote lines using [quoteID]",
            tables: ["RAC_QUOTE_LINES", "ITEMS"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quotePOs: {
            query: "SELECT * "
                + "FROM RAC_POS rp "
                + "WHERE rp.nAttachedQuote=[quoteID] "
                + "ORDER BY rp.id asc",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about POs using [quoteID]",
            tables: ["RAC_POS"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quotePOLines: {
            query: "SELECT * "
                + "FROM RAC_PO_LINES rpl "
                + "WHERE rpl.nQuote IN (SELECT id FROM RAC_POS rp WHERE nAttachedQuote=[quoteID])",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about PO lines using [quoteID]",
            tables: ["RAC_POS", "RAC_PO_LINES"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteAttachments: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_ATTACHMENTS rqa "
                + "WHERE rqa.nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote attachments using [quoteID]",
            tables: ["RAC_QUOTE_ATTACHMENTS"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteFollowups: {
            query: "SELECT * "
                + "FROM RAC_SCHEDULE rs "
                + "WHERE rs.nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote followups using [quoteID]",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteNotes: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "WHERE rqn.nQuote=[quoteID] AND nType!=2 "
                + "ORDER BY rqn.id desc",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote notes using [quoteID]",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteLog: {
            query: "SELECT * "
                + "FROM QUOTE_LOG ql "
                + "WHERE ql.nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote log using [quoteID]",
            tables: ["QUOTE_LOG"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        quoteEmails: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_EMAILS rqe "
                + "WHERE rqe.nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote emails using [quoteID]",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        
        quotePackages: {
            query: "SELECT * "
                + "FROM PACKAGES p "
                + "WHERE p.nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets information about quote packages using [quoteID]",
            tables: ["PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/Quote"],
        },
        updateQuote: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET [changes] "
                + "WHERE id=[quoteID]",
            req: ["changes", "quoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a Quote with an array of [changes]",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/pages/Quote"],
        },
        insertQuoteLog: {
            query: "INSERT INTO QUOTE_LOG "
                + "(nQuote, dtWhen, sWhat, nRep) "
                + "VALUES ([quoteID], GETDATE(), [logText], [repID])",
            req: ["logText", "quoteID", "repID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Creates a quote log for updating a quote [quoteID] with [changes] from rep [repID]",
            tables: ["QUOTE_LOG"],
            type: "INSERT",
            whereUsed: ["components/pages/Quote"],
        },
        
    }

} 