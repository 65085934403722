import React, { Component } from 'react';



class QuoteLine extends Component {

    render() {
      
        const sql = this.props.sql
        const index = this.props.index
        return (
            <tr key={sql.id} onClick={()=>this.props.handleClickQuoteLine(sql, index)}>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  {sql.nQuantity}
                </td>
                <td contentEditable="true" style={{ textAlign: 'left' }}>
                  {sql.nItem}
                </td>
                <td contentEditable="true" style={{ whiteSpace: 'pre-wrap' }}>
                  {sql.sDescription}
                </td>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  $
                  {sql.nPrice
                    ? sql.nPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : null}
                </td>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  $
                  {(sql.nPrice * sql.nQuantity).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                    {sql.POCost ? sql.POCost : 0}
                </td>
                <td>
                  {sql.Margin}
                </td>
              </tr>
        );
    }
}

export default QuoteLine;