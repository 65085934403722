import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { percentTwoDecimal } from '../../../assets/functions/display';



export default class NewQuotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>Rep</th>
              <th>Date</th>
              <th>Sent</th>
              <th>Status</th>
              <th>Total Price</th>
              <th>Type</th>
              <th>Campaign</th>
              <th>Lead Source</th>
              <th>Referring Site</th>
              <th>New Customer</th>
              <th>Potential</th>
              <th>Likely To Close</th>
              <th>RFQs Sent</th>
              <th>Full Match Responses</th>
            </tr>
          </thead>
          <tbody>
            {this.props.quotes.map((quote, index)=>(
              <>
              <tr key={index}>
                <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                <td>{quote.sCompany}</td>
                <td>{whichRep(quote.nRep)}</td>
                <td>{moment(quote.dtDateStamp).utc().format('MM/DD/YYYY hh:mm A')}</td>
                <td>{quote.dtSentToCustomer ? moment(quote.dtSentToCustomer).utc().format('MM/DD/YYYY') : ""}</td>
                <td style={{color: quote.sStatus === "Closed" ? cGreen : quote.sStatus === "Discontinued" ? cRed : ""}}>{(quote.sWhyCancel && quote.sStatus==="Discontinued") ? quote.sWhyCancel : quote.sStatus}</td>
                <td>{nQuoteTotalNoDollar(quote.nLinesTotal+quote.nDelivery)}</td>
                <td>{quote.sType}</td>
                <td>{quote.sCampaign}</td>
                <td>{quote.sLeadSource}</td>
                <td>{quote.sReferringSite}</td>
                <td>{quote.NewCustomer ? "Yes" : ""}</td>
                <td>{quote.nPotential}</td>
                <td>{quote.nLikelyToClose}</td>
                <td>{quote.RFQsSent}</td>
                <td>{quote.RFQsFullMatch}</td>
              </tr>
              </>
            ))}
          </tbody>
        </table>
    );
  }
}
