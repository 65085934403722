import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import QuoteHeader from '../quote/QuoteHeader';
import QuoteOverview from '../quote/quote-overview/QuoteOverview';
import QuoteLines from '../quote/quote-lines/QuoteLines';
import PoLines from '../quote/po-lines/PoLines';
import Communications from '../quote/communications/Communications';
import Rfqs from '../quote/rfqs/Rfqs';
import Logistics from '../quote/logistics/Logistics';
import CostSheet from '../quote/cost-sheet/CostSheet';
import SideBar from '../quote/sidebar/SideBar';
import Loader from 'react-loader-spinner';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { buildAddress, formatQuoteChangeLog, generateCustomerCSV, generateInvoiceCSV, prepareStringForSQL, processQuoteChanges, splitPackages } from '../../assets/functions/system';
import { fetchUser } from '../../assets/functions/user';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { whichRep } from '../../assets/functions/calculations';
//import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import DocuSignPDF from '../quote/pdf/DocuSignPDF';
import { calcQuoteLinePOCost, returnAssignedPOLines, returnUnassignedPOLines } from '../../assets/functions/quote';

const Wrapper = styled.div`
  display: flex;
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 1em 2em;
`;

const LoaderWrapper = styled.div`
  margin: 25vh auto 0 auto;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #4aabed;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #2a8acb;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export default class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      viewing: "Quote Overview",
      changes: [],
      assignCostToLine: 0,
    };

    this.updateData = this.updateData.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.saveQuote = this.saveQuote.bind(this)
    this.changePaidStatus = this.changePaidStatus.bind(this)
    this.handleClickQuoteLine = this.handleClickQuoteLine.bind(this)
    this.handleAssignPOLine = this.handleAssignPOLine.bind(this)
  }

  handleOnClick = (viewing) => {

    this.setState({ viewing: viewing });
  };

  handleSideBarAction = (event) => {
    this.setState({
      showSideBar: true,
      action: event,
    });
    if (this.state.showSideBar === true && event === this.state.action) {
      this.setState({
        showSideBar: false,
      });
    }
  };

  handleSideBarClose = () => {
    this.setState({
      showSideBar: false,
    });
  };


  fetchData = async (id) => {

    try {
      const fetchQuoteData = await getSQLData("quote/quoteData", {quoteID: id})
      const fetchQuoteLines = await getSQLData("quote/quoteLines", {quoteID: id})
      const fetchQuotePOs = await getSQLData("quote/quotePOs", {quoteID: id})
      const fetchQuotePOLines = await getSQLData("quote/quotePOLines", {quoteID: id})
      const fetchQuoteAttachments = await getSQLData("quote/quoteAttachments", {quoteID: id})
      const fetchQuoteFollowups = await getSQLData("quote/quoteFollowups", {quoteID: id})
      const fetchQuoteNotes = await getSQLData("quote/quoteNotes", {quoteID: id})
      const fetchQuoteLog = await getSQLData("quote/quoteLog", {quoteID: id})
      const fetchQuoteEmails = await getSQLData("quote/quoteEmails", {quoteID: id})
      const fetchQuotePackages = await getSQLData("quote/quotePackages", {quoteID: id})
      const user = await fetchUser()
      
      if(fetchQuoteData && fetchQuoteLines && fetchQuotePOs && fetchQuotePOLines && fetchQuoteAttachments && fetchQuoteFollowups && 
        fetchQuoteNotes && fetchQuoteLog && fetchQuoteEmails && fetchQuotePackages && user){

        let data = fetchQuoteData.data.data[0].recordset[0]    
        let attachments = fetchQuoteAttachments.data.data[0].recordset    
        let followups = fetchQuoteFollowups.data.data[0].recordset    
        let notes = fetchQuoteNotes.data.data[0].recordset    
        let log = fetchQuoteLog.data.data[0].recordset    
        let emails = fetchQuoteEmails.data.data[0].recordset    
        let packages = fetchQuotePackages.data.data[0].recordset    
        let quoteLines = fetchQuoteLines.data.data[0].recordset    
        let POs = fetchQuotePOs.data.data[0].recordset    
        let quotePOLines = fetchQuotePOLines.data.data[0].recordset    


        let query = {
          data: fetchQuoteData.query,
          attachments: fetchQuoteAttachments.query,
          followups: fetchQuoteFollowups.query,
          notes: fetchQuoteNotes.query,
          log: fetchQuoteLog.query,
          emails: fetchQuoteEmails.query,
          packages: fetchQuotePackages.query,
          quoteLines: fetchQuoteLines.query,
          POs: fetchQuotePOs.query,
          quotePOLines: fetchQuotePOLines.query,
        }       
        
        this.setState({
          data: data,
          followups: followups,
          attachments: attachments,
          notes: notes,
          log: log,
          emails: emails,
          packages: packages,
          quoteLines: quoteLines,
          POs: POs,
          quotePOLines: quotePOLines,
          query: query,
          user: user.user,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {
    let logText = "'Quote viewed by " + whichRep(this.state.user) + " on cfrinc.app'"
    setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})

    let packages = this.state.packages
    let packageData = splitPackages(packages)

    let data = this.state.data
    let quoteLines = this.state.quoteLines
    let quotePOLines = this.state.quotePOLines

    quoteLines = calcQuoteLinePOCost(quoteLines, quotePOLines)
    let unassignedPOLines = returnUnassignedPOLines(quotePOLines)

    let invoice = generateInvoiceCSV(data, quoteLines)
    let customer = generateCustomerCSV(data)

    this.setState({
      deliveryPackages: packageData.deliveryPackages,
      returnPackages: packageData.returnPackages,
      InvoiceData: invoice.Data,
      InvoiceHeaders: invoice.Headers,
      CustomerData: customer.Data,
      CustomerHeaders: customer.Headers,
      quoteLines: quoteLines,
      unassignedPOLines: unassignedPOLines,
      loaded: true,
    })
  }

  componentDidMount() {

    this.fetchData(this.props.history.location.pathname.slice(-7))
    
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ loaded: false });
      this.fetchData(this.props.history.location.pathname.slice(-7));
    }
  }

  updateData(getField, getData) {

    console.log(getField,getData)
    let data = this.state.data
    let changes = this.state.changes

    data[getField] = getData
    
    if(getField[0]=="n"){
      changes[getField] = getData
    }
    if(getField[0]=="s"){
      changes[getField] = prepareStringForSQL(getData)
    }    
    if(getField=="[paymentMethod]"){

    }
    

    this.setState({
      data: data,
      changes: changes,
    })
  }

  updateQuoteLines = async (quoteLines) => {
    let changeLines = []
    let newLines = []
    for(let i=0; i<quoteLines.length; i++){
      if(quoteLines[i].new){
        newLines.push(quoteLines[i])
      } else if(quoteLines[i].changed){
        changeLines.push(quoteLines[i])
      }
    }
    try {
      if(newLines.length > 0){
        for(let i=0; i<newLines.length; i++){
          let line = newLines[i]
          const updateLine = await setSQLData("quote/insertQuoteLine", {
            id: line.id,
            bTax: line.bTax,
            nItem: line.nItem,
            nPO: line.nPO,
            nPrice: line.nPrice,
            nQuantity: line.nQuantity,
            nSort: line.nSort,
          })
        }
      }

      if(changeLines.length > 0){
        for(let i=0; i<changeLines.length; i++){
          let line = changeLines[i]
          const updateLine = await setSQLData("quote/updateQuoteLine", {
            id: line.id,
            bTax: line.bTax,
            nItem: line.nItem,
            nPO: line.nPO,
            nPrice: line.nPrice,
            nQuantity: line.nQuantity,
            nSort: line.nSort,
          })
        }
      }
      


    } catch {

    }
  }

  saveQuote = async () => {
    let quoteLines = this.state.quoteLines
    try {
      let changesText = processQuoteChanges(this.state.changes)
      let logText = formatQuoteChangeLog(changesText, this.state.data.id)
      const changes = await setSQLData("quote/updateQuote", {changes: changesText, quoteID: this.state.data.id})
      if(changes){
        const log = await setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})
        const quoteLines = await this.updateQuoteLines(quoteLines)
      }
    } catch(error){
      console.error(error)
    }
  }

  changePaidStatus(){
    let data = this.state.data

    data.nPaid = !data.nPaid
    
    setSQLData("quote/setPaidStatus", {paidStatus: data.nPaid ? 1 : 0, quoteNum: data.id})
    let logText = "'Paid status updated to " + (data.nPaid ? "Paid" : "Unpaid") + " by " + whichRep(this.state.user) + " on cfrinc.app'"
    setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})

    this.setState({data: data})

  }

  handleClickQuoteLine(line, index) {
    line.index = index
    this.setState({
      assignCostToLine: line,
    })
  }
  handleCloseAssignPOLine() {
    this.setState({
      assignCostToLine: 0,
    })
  }

  handleUnassignPOLine(line, index){
    let assignCostToLine = this.state.assignCostToLine
    let quotePOLines = this.state.quotePOLines
    let quoteLines = this.state.quoteLines
    let quotePOLineIndex = returnAssignedPOLines(quotePOLines, assignCostToLine)[index].index
    

    setSQLData("quote/assignPOLine", {
      quoteLine: 0,
      POLine: line.id,
    })
    
    quotePOLines[quotePOLineIndex].nQuoteLine = 0
    
    quoteLines = calcQuoteLinePOCost(quoteLines, quotePOLines)
    let unassignedPOLines = returnUnassignedPOLines(quotePOLines)
    assignCostToLine = 0

    this.setState({
      quotePOLines: quotePOLines,
      quoteLines: quoteLines,
      assignCostToLine: assignCostToLine,
      unassignedPOLines: unassignedPOLines,
    })
  }

  handleAssignPOLine(line, index){
    let assignCostToLine = this.state.assignCostToLine
    let quotePOLines = this.state.quotePOLines
    let quoteLines = this.state.quoteLines
    let quotePOLineIndex = this.state.unassignedPOLines[index].index

    setSQLData("quote/assignPOLine", {
      quoteLine: assignCostToLine.id,
      POLine: line.id,
    })
    
    quotePOLines[quotePOLineIndex].nQuoteLine = assignCostToLine.id
    
    quoteLines = calcQuoteLinePOCost(quoteLines, quotePOLines)
    let unassignedPOLines = returnUnassignedPOLines(quotePOLines)
    assignCostToLine = 0

    this.setState({
      quotePOLines: quotePOLines,
      quoteLines: quoteLines,
      assignCostToLine: assignCostToLine,
      unassignedPOLines: unassignedPOLines,
    })
  }

  handleChangeQuoteLine(line, property, value){
    let quoteLines = this.state.quoteLines
    quoteLines[line][property] = value
    quoteLines[line].changed = true

    this.setState({
      quoteLines: quoteLines,
    })
  }

  handleAddQuoteLine(line, value){
    let quoteLines = this.state.quoteLines
    value.new = true
    quoteLines.push(value)
    this.setState({
      quoteLines: quoteLines,
    })
  }

  render() {
    let assignCostToLine = this.state.assignCostToLine
    let unassignedPOLines = this.state.unassignedPOLines
    let quotePOLines = this.state.quotePOLines
    let assignedPOLines = returnAssignedPOLines(quotePOLines, assignCostToLine)
    return (
      <Wrapper>
        {this.state.loaded || (
          <LoaderWrapper>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </LoaderWrapper>
        )}
        {this.state.loaded && (
          <>
            <BodyWrapper
              style={{
                // Sets the width of the quote page when the sidebar is open
                width: this.state.showSideBar && '77.1%',
              }}
            >
              <button>
                <CSVLink data={this.state.InvoiceData} headers={this.state.InvoiceHeaders} filename={"invoice"}>Download Invoice</CSVLink>
              </button>
              <button>
                <CSVLink data={this.state.CustomerData} headers={this.state.CustomerHeaders} filename={"customer"}>Download Customer</CSVLink>
              </button>
              <div>
                {console.log(this.state.data)}
                {//<PDFDownloadLink document={<DocuSignPDF data={this.state.data} />} fileName={this.state.data.id + ".pdf"}>
                //  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                //</PDFDownloadLink>
                }
              </div>
              <QuoteHeader
                handleOnClick={this.handleOnClick}
                update={this.updateData}
                saveQuote={this.saveQuote}
                changePaidStatus={this.changePaidStatus}
                viewing={this.state.viewing}
                data={this.state.data}
              />
              
              {this.state.viewing == "Quote Overview" && (
                <QuoteOverview
                  onClick={this.handleSideBarAction}
                  data={this.state.data}
                  followupsCount={this.state.followups.length}
                  attachmentsCount={this.state.attachments.length}
                  socialMediaCount={
                    this.state.data.sSocialMedia
                      ? this.state.data.sSocialMedia.split(',').length
                      : 0
                  }
                  update={this.updateData}
                />
              )}

              {this.state.viewing == "Quote Lines" && (
                <QuoteLines
                  data={this.state.data}
                  quoteLines={this.state.quoteLines}
                  POs={this.state.POs}
                  handleClickQuoteLine={this.handleClickQuoteLine}
                />
              )}
              {this.state.viewing == "PO Lines" && (
                <PoLines POs={this.state.POs} />
              )}
              {this.state.viewing == "Communications" && (
                <Communications
                  notes={this.state.notes}
                  log={this.state.log}
                  emails={this.state.emails}
                  data={this.state.data}
                />
              )}
              {this.state.viewing == "RFQs" && (
                <Rfqs 
                  quote={this.state.data.id} 
                  quoteLines={this.state.quoteLines}
                /> 
              )}
              {this.state.viewing == "Logistics" && (
                <Logistics
                  data={this.state.data}
                  deliveryPackages={this.state.deliveryPackages}
                  returnPackages={this.state.returnPackages}
                />
              )}
              {this.state.viewing == "Cost Sheet" && (
                <CostSheet
                  data={this.state.data}
                  quoteLines={this.state.quoteLines}
                  POs={this.state.POs}
                />
              )}
            </BodyWrapper>
            {this.state.showSideBar ? (
              <SideBar
                action={this.state.action}
                onClick={this.handleSideBarClose}
                attachments={this.state.attachments}
                followups={this.state.followups}
                socialMedia={this.state.data.sSocialMedia}
              />
            ) : null}
          </>
        )}

        {assignCostToLine && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Test</ModalTitle>
              </ModalHeader>

            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
            </table>
              <tr style={{fontWeight: 'bold'}}>
                <td>{assignCostToLine.nQuantity}</td>
                <td>{assignCostToLine.sDescription}</td>
                <td>{assignCostToLine.nPrice}</td>
                <td>{assignCostToLine.nPrice*assignCostToLine.nQuantity}</td>
              </tr>
              <br />
              {assignedPOLines.length > 0 && (
                <>
                  <div><b>Assigned PO Lines</b></div>
                </>
              )}
              {assignedPOLines.map((line,index)=> (
                <tr onClick={()=>this.handleUnassignPOLine(line,index)}>
                  <td>{line.nQuantity}</td>
                  <td>{line.sDescription}</td>
                  <td>{line.nPrice}</td>
                  <td>{line.nPrice*line.nQuantity}</td>
                </tr>
              ))}
              {unassignedPOLines.length > 0 && (
                <>
                  <div><b>Unassigned PO Lines</b></div>
                </>
              )}
              {unassignedPOLines.map((line,index) => (
                <tr onClick={()=>this.handleAssignPOLine(line, index)}>
                  <td>{line.nQuantity}</td>
                  <td>{line.sDescription}</td>
                  <td>{line.nPrice}</td>
                  <td>{line.nPrice*line.nQuantity}</td>
                </tr>
              ))}
            <ButtonContainer>
              <Button onClick={()=>this.handleCloseAssignPOLine()}>Close</Button>
            </ButtonContainer>
            </ModalContent>
          </ModalOverlay>
        )}



        
      </Wrapper>
    );
  }
}
