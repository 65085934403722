import React, { Component } from 'react';
import styled from 'styled-components';

class QuoteTotalSection extends Component {
    render() {
        let nSubTotal = this.props.nSubTotal
        let nDelivery = this.props.nDelivery
        let nTax = this.props.nTax
        let nTaxRate = this.props.nTaxRate

        return (
            <>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Sub Total:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {nSubTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Delivery & Pickup / Shipping:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {nDelivery
                  ? nDelivery.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : null}
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ textAlign: 'right' }}>
                <input type="checkbox" id="" name="" value="" /> Tax Delivery &
                Pickup?{' '}
                <span style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Tax ( {nTaxRate} %):
                </span>
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {nTax.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Total:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {(nSubTotal + nDelivery + nTax).toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </td>
            </tr>
            </>
        );
    }
}

export default QuoteTotalSection;