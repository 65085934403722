import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations';
import moment from 'moment';

class MonthProjectionsTable extends Component {
    render() {
        let months = this.props.months
        let lastYear = this.props.lastYear
        let total = this.props.total
        let lastYearTotal = this.props.lastYearTotal

        return (
            <table className="dashboard">
            <thead>
              <tr>
                <th>Month</th>
                <th>Margin Complete</th>
                <th>Margin Projected</th>
                <th>Last Year</th>
              </tr>
            </thead>
            {months.map((month, index)=>(
              <tr>
                <td>{moment().month(index).format('MMMM')}</td>
                <td>{nQuoteTotalNoDollar(month.marginComplete+month.laborComplete)}</td>
                <td>{nQuoteTotalNoDollar(month.marginComplete+month.laborComplete+month.marginProjected+month.laborProjected)}</td>
                <td>{nQuoteTotalNoDollar(lastYear[index].marginComplete+lastYear[index].laborComplete)}</td>
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td>{nQuoteTotalNoDollar(total.marginComplete+total.laborComplete)} ({Math.floor((total.marginComplete+total.laborComplete)/800000*100)}%)</td>
              <td>{nQuoteTotalNoDollar(total.marginComplete+total.laborComplete+total.marginProjected+total.laborProjected)} ({Math.floor((total.marginComplete+total.laborComplete+total.marginProjected+total.laborProjected)/800000*100)}%)</td>
              <td>{nQuoteTotalNoDollar(lastYearTotal.marginComplete+lastYearTotal.laborComplete)}</td>
            </tr>
          </table>
        );
    }
}

export default MonthProjectionsTable;