module.exports = {
    email_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getUserEmail: {
            query: "SELECT sEmail FROM RAC_REPS WHERE id=[userID]",
            req: ["userID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets a user's email address",
            tables: ["RAC_REPS"],
            type: "SELECT",
            whereUsed: [],
        },
        cannedResponses: {
            query: "SELECT * FROM CANNED_RESPONSES WHERE nRep = 0 OR nRep = [nRep] ORDER BY sName asc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        quoteAttachments: {
            query: "SELECT id,sName FROM RAC_QUOTE_ATTACHMENTS WHERE nQuote=[nQuote]",
            req: ["nQuote"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        globalAttachments: {
            query: "SELECT id,sName, sLocation FROM GLOBAL_ATTACHMENTS",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },

				
    }

} 