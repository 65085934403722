import React, { Component } from 'react';
import DiscontinuedQuoteViewer from '../tables/discontinued-quote-review/DiscontinuedQuoteViewer';
import DiscontinuedQuoteQuestions from '../tables/discontinued-quote-review/DiscontinuedQuoteQuestions';
import { getSQLData } from '../../assets/functions/fetch';

class DiscontinuedQuoteReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            confirm: 0,
        };
      }

    componentDidMount() {
        this.fetchData()
      }
    
      fetchData = async () => {
    
        try {
          const fetch = await getSQLData("admin/discontinuedQuoteReview", {})
          const fetchLines = await getSQLData("admin/discontinuedQuoteReviewLines", {})
          const fetchNotes = await getSQLData("admin/discontinuedQuoteReviewNotes", {})
          const fetchEmails = await getSQLData("admin/discontinuedQuoteReviewEmails", {})
          const fetchReps = await getSQLData("admin/activeReps", {})

          
          if(fetch && fetchLines && fetchNotes && fetchEmails && fetchReps){
    
            let quote = fetch.data.data[0].recordset ? fetch.data.data[0].recordset[0] : null
            let lines = fetchLines.data.data[0].recordset
            let notes = fetchNotes.data.data[0].recordset
            let emails = fetchEmails.data.data[0].recordset
            let reps = fetchReps.data.data[0].recordset

            this.setState({
                quote: quote,
                lines: lines,
                notes: notes,
                emails: emails,
                reps: reps,
            }, () => this.processData())
          }
             
        } catch (error) {
          console.error(error);
        }
      }

      
      processData() {
        

        this.setState({
            loaded: true,
        })
      }

     

    render() {

        let loaded = this.state.loaded
        let quote = this.state.quote
        let lines = this.state.lines
        let notes = this.state.notes
        let emails = this.state.emails
        let reps = this.state.reps
        let user = this.props.user

        return (
            <div>
                {loaded && (
                    <table>
                        <tr>
                            <td style={{
                                verticalAlign: 'top',
                                width: '75%',
                            }}>
                                <DiscontinuedQuoteViewer 
                                    quote= {quote}
                                    lines= {lines}
                                    notes= {notes}
                                    emails= {emails}
                                    reps= {reps}
                                />
                            </td>
                        
                            <td style={{
                                verticalAlign: 'top',
                                width: '25%',
                            }}>
                                <DiscontinuedQuoteQuestions 
                                    quote= {quote}
                                    lines= {lines}
                                    notes= {notes}
                                    emails= {emails}
                                    reps= {reps}
                                    user= {user}
                                />
                            </td>
                        </tr>
                    </table>
                )}
            </div>
        );
    }
}

export default DiscontinuedQuoteReview;