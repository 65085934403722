// TierWizard.js
import React, { Component } from 'react';
import ClosedQuotesModal from '../tables/marketing-wizard/ClosedQuotesModal';
import DiscontinuedQuotesModal from '../tables/marketing-wizard/DiscontinuedQuotesModal';
import QuotesReviewReport from '../tables/marketing-wizard/QuotesReviewReport';
import { ButtonContainer, Wrapper, ViewButton } from '../tables/marketing-wizard/StyledComponents';
import BlastEmailEditor from '../tables/marketing-wizard/BlastEmailEditor';

export default class TierWizard extends Component {
  state = {
    view: null,
  };

  handleViewChange = (viewType) => {
    this.setState({ view: viewType });
  };

  render() {
    const { view } = this.state;
    const { user } = this.props;
    console.log("User prop in TierWizard:", user);
  
    return (
      <Wrapper>
        <ButtonContainer>
          <ViewButton onClick={() => this.handleViewChange('closed')}>View Closed Quotes</ViewButton>
          <ViewButton onClick={() => this.handleViewChange('discontinued')}>View Discontinued Quotes</ViewButton>
          <ViewButton onClick={() => this.handleViewChange('review')}>View Quote Review Report</ViewButton>
          <ViewButton onClick={() => this.handleViewChange('blast')}>Edit Blast Emails</ViewButton>
        </ButtonContainer>
  
        {view === 'closed' && <ClosedQuotesModal user={user} />}
        {view === 'discontinued' && <DiscontinuedQuotesModal user={user} />}
        {view === 'review' && <QuotesReviewReport />}
        {view === 'blast' && <BlastEmailEditor />}
      </Wrapper>
    );
  }
  
}
