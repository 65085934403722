import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faEnvelope, faInfoCircle, faCheckCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import EmailDetailsModal from './EmailDetailsModal';
import CompanyDetailsModal from './CompanyDetailsModal';
import { StyledTable, IconButton } from './StyledComponents';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

const NoteEntry = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#f9f9f9' : '#e9e9e9')};
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const EmailEntry = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#f1f1f1' : '#d1d1d1')};
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const ContactInfo = styled.div`
  background-color: #f4f4f9;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  line-height: 1.5;
`;

const ContactLabel = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

class DiscontinuedQuotesModal extends Component {
  state = {
    discontinuedQuotes: [],
    notes: {},
    emails: {},
    quoteLines: {},
    activeNotes: {},
    activeEmails: {},
    selectedEmail: null,
    selectedCompany: null,
    selectedQuoteId: null,
    reviewInProgress: false,
    loading: true,
  };

  componentDidMount() {
    this.loadDiscontinuedQuotes();
  }

  loadDiscontinuedQuotes = async () => {
    this.setState({ loading: true });
    try {
      const discontinuedQuotesFetch = await getSQLData("logistics/discontinuedQuotes", { numQuotes: 250 });
      if (discontinuedQuotesFetch?.data?.data[0]) {
        const discontinuedQuotes = discontinuedQuotesFetch.data.data[0].recordset;
        this.setState({ discontinuedQuotes, loading: false }, () => {
          const quoteToDisplay = discontinuedQuotes.find(
            (quote) => !quote.dtFinalReview || (quote.dtFinalReview && !quote.nFinalReview)
          );
          if (quoteToDisplay) {
            this.fetchAllNotesAndEmails(quoteToDisplay.id);
            this.fetchQuoteLines(quoteToDisplay.id);
          }
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error('Error loading discontinued quotes:', error);
      this.setState({ loading: false });
    }
  };

  fetchAllNotesAndEmails = async (quoteId) => {
    try {
      const notesFetch = await getSQLData("logistics/notesDiscontinued", { quoteId });
      const emailsFetch = await getSQLData("logistics/emailsDiscontinued", { quoteId });

      const notesRecordset = notesFetch.data?.data[0]?.recordset || [];
      const emailsRecordset = emailsFetch.data?.data[0]?.recordset || [];

      this.setState({
        notes: { [quoteId]: notesRecordset },
        emails: { [quoteId]: emailsRecordset },
      });
    } catch (error) {
      console.error("Error fetching notes or emails:", error);
    }
  };

  fetchQuoteLines = async (quoteId) => {
    try {
      const quoteLinesFetch = await getSQLData('logistics/quoteLines', { quoteIds: quoteId });
      const quoteLinesRecordset = quoteLinesFetch.data?.data[0]?.recordset || [];

      this.setState({
        quoteLines: { [quoteId]: quoteLinesRecordset },
      });
    } catch (error) {
      console.error('Error fetching quote lines:', error);
    }
  };

  handleStartReview = async (quoteId) => {
    const reviewer = this.props.user; // Use the entire user object, as in ClosedQuotesModal
    console.log("Attempting to start review for quote ID:", quoteId);

    try {
        this.setState({ selectedQuoteId: quoteId, reviewInProgress: true, loading: true });
        await setSQLData("logistics/updateFinalReviewStart", { quoteId, nFinalReview: reviewer });
        console.log("Review started for quote ID:", quoteId);
        this.setState({ loading: false });
    } catch (error) {
        console.error("Error starting review:", error);
        this.setState({ loading: false });
    }
};



  handleCompleteReview = async (quoteId) => {
    const reviewer = this.props.user; // Use the entire user object, as in ClosedQuotesModal
    this.setState({ loading: true });

    try {
        await setSQLData('logistics/updateFinalReviewComplete', { quoteId, nFinalReview: reviewer });
        this.setState({ selectedQuoteId: null, reviewInProgress: false }, this.loadDiscontinuedQuotes);
    } catch (error) {
        console.error("Error completing review:", error);
        this.setState({ loading: false });
    }
};


  toggleNotes = (quoteId) => {
    this.setState((prevState) => ({
      activeNotes: {
        ...prevState.activeNotes,
        [quoteId]: !prevState.activeNotes[quoteId],
      },
    }));
  };

  toggleEmails = (quoteId) => {
    this.setState((prevState) => ({
      activeEmails: {
        ...prevState.activeEmails,
        [quoteId]: !prevState.activeEmails[quoteId],
      },
    }));
  };

  openEmailModal = (email) => {
    this.setState({ selectedEmail: email });
  };

  closeEmailModal = () => {
    this.setState({ selectedEmail: null });
  };

  openCompanyDetailsModal = (quote) => {
    const { nCompany, PrimaryContactName, PrimaryContactEmail, PrimaryContactPhone, nRep } = quote;
    if (nCompany) {
      this.setState({
        selectedCompany: {
          companyId: nCompany,
          companyName: quote.sCompany,
          quoteId: quote.id,
          repId: nRep,
          contactInfo: {
            name: PrimaryContactName || '',
            email: PrimaryContactEmail || '',
            phone: PrimaryContactPhone || '',
            tier: '4',
          },
        },
      });
    } else {
      alert(`Company information is unavailable for this quote (ID: ${quote.id}).`);
    }
  };

  closeCompanyDetailsModal = () => {
    this.setState({ selectedCompany: null });
  };

  calculateMargin = (quoteTotal, totalCost) => {
    if (!quoteTotal || !totalCost) return 'N/A';
    const margin = ((quoteTotal - totalCost) / quoteTotal) * 100;
    return `${margin.toFixed(2)}%`;
  };

  render() {
    const {
      discontinuedQuotes,
      notes,
      emails,
      quoteLines,
      activeNotes,
      activeEmails,
      selectedEmail,
      selectedCompany,
      selectedQuoteId,
      reviewInProgress,
      loading,
    } = this.state;

    const quotesLeftToReview = discontinuedQuotes.filter(
      (quote) => !quote.dtFinalReview || (quote.dtFinalReview && !quote.nFinalReview)
    ).length;

    const quoteToDisplay = discontinuedQuotes.find(
      (quote) => !quote.dtFinalReview || (quote.dtFinalReview && !quote.nFinalReview)
    );

    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      );
    }

    if (!quoteToDisplay) {
      return <p>No discontinued quotes left to review.</p>;
    }

    return (
      <>
        <div key={quoteToDisplay.id}>
          <h2>Discontinued Quote Review ({quotesLeftToReview} Quotes Left to Review)</h2>
          <StyledTable>
            <thead>
              <tr>
                <th>Field</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Review Status</td>
                <td>
                  {selectedQuoteId === quoteToDisplay.id && reviewInProgress ? (
                    <button onClick={() => this.handleCompleteReview(quoteToDisplay.id)}>
                      <FontAwesomeIcon icon={faCheckCircle} /> Complete Review
                    </button>
                  ) : (
                    <button onClick={() => this.handleStartReview(quoteToDisplay.id)}>
                      <FontAwesomeIcon icon={faPlayCircle} /> Start Review
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <td>Company</td>
                <td>
                  {quoteToDisplay.sCompany}
                  <IconButton onClick={() => this.openCompanyDetailsModal(quoteToDisplay)} style={{ marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </IconButton>
                </td>
              </tr>
              <tr>
                <td>Quote ID</td>
                <td>
                  <a href={`https://cfrinc.app/#/quote/${quoteToDisplay.id}`} target="_blank" rel="noopener noreferrer">
                    {quoteToDisplay.id}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Primary Contact</td>
                <td>
                  {quoteToDisplay.PrimaryContactName ? (
                    <ContactInfo>
                      <p><ContactLabel>Name:</ContactLabel> <span dangerouslySetInnerHTML={{ __html: quoteToDisplay.PrimaryContactName }} /></p>
                      <p><ContactLabel>Phone:</ContactLabel> <span dangerouslySetInnerHTML={{ __html: quoteToDisplay.PrimaryContactPhone || 'N/A' }} /></p>
                      <p><ContactLabel>Email:</ContactLabel> <span dangerouslySetInnerHTML={{ __html: quoteToDisplay.PrimaryContactEmail || 'N/A' }} /></p>
                    </ContactInfo>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
              <tr>
                <td>CFR Brand</td>
                <td>{quoteToDisplay.CFRBrand}</td>
              </tr>
              <tr>
                <td>Order Dollar Amount</td>
                <td>${quoteToDisplay.nQuoteTotal}</td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td>{moment(quoteToDisplay.dtLastUpdated).utcOffset(0).format('MM/DD/YYYY hh:mm A')}</td>
              </tr>
              <tr>
                <td>Sales Margin</td>
                <td>{this.calculateMargin(quoteToDisplay.nQuoteTotal, quoteToDisplay.TotalCost)}</td>
              </tr>
              <tr>
                <td>Sales Rep</td>
                <td>{repNames[quoteToDisplay.nRep] || `Rep ID: ${quoteToDisplay.nRep}`}</td>
              </tr>
              <tr>
                <td>Reason for Cancellation</td>
                <td>{quoteToDisplay.sWhyCancel || 'N/A'}</td>
              </tr>
              <tr>
                <td>Quote Lines</td>
                <td>
                  {quoteLines[quoteToDisplay.id] && quoteLines[quoteToDisplay.id].length > 0 ? (
                    <StyledTable>
                      <thead>
                        <tr>
                          <th>Quantity</th>
                          <th>Description</th>
                          <th>Price/EA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteLines[quoteToDisplay.id].map((line, idx) => (
                          <tr key={idx}>
                            <td>{line.nQuantity}</td>
                            <td>{line.sDescription}</td>
                            <td>${line.nPrice.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                  ) : (
                    <p>No line items available.</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>
                  {notes[quoteToDisplay.id] && notes[quoteToDisplay.id].length > 0 ? (
                    <>
                      <IconButton onClick={() => this.toggleNotes(quoteToDisplay.id)}>
                        <FontAwesomeIcon icon={faStickyNote} />
                      </IconButton>
                      {activeNotes[quoteToDisplay.id] && notes[quoteToDisplay.id].map((note, idx) => (
                        <NoteEntry key={idx} index={idx}>
                          <div dangerouslySetInnerHTML={{ __html: `${note.sNote} <small>by ${repNames[note.nRep]}</small>` }} />
                        </NoteEntry>
                      ))}
                    </>
                  ) : (
                    'No notes available.'
                  )}
                </td>
              </tr>
              <tr>
                <td>Emails</td>
                <td>
                  {emails[quoteToDisplay.id] && emails[quoteToDisplay.id].length > 0 ? (
                    <>
                      <IconButton onClick={() => this.toggleEmails(quoteToDisplay.id)}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </IconButton>
                      {activeEmails[quoteToDisplay.id] && emails[quoteToDisplay.id].map((email, idx) => (
                        <EmailEntry key={idx} index={idx} onClick={() => this.openEmailModal(email)}>
                          <strong>{email.sSubject}</strong><br />
                          From: {email.sFrom} | To: {email.sTo}
                        </EmailEntry>
                      ))}
                    </>
                  ) : (
                    'No emails available.'
                  )}
                </td>
              </tr>
            </tbody>
          </StyledTable>

          {selectedEmail && <EmailDetailsModal email={selectedEmail} onClose={this.closeEmailModal} />}
          {selectedCompany && selectedCompany.companyId && (
            <CompanyDetailsModal
              companyId={selectedCompany.companyId}
              companyName={selectedCompany.companyName}
              quoteId={selectedCompany.quoteId}
              repId={selectedCompany.repId}
              contactInfo={selectedCompany.contactInfo}
              onClose={this.closeCompanyDetailsModal}
            />
          )}
        </div>
      </>
    );
  }
}

export default DiscontinuedQuotesModal;
