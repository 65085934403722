import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { thisYearStart, dayChangeEnd } from '../../assets/functions/time';
import OpenInstalls from '../tables/cam-operations/OpenInstalls';
import RecentlyCompleted from '../tables/cam-operations/RecentlyCompleted';
import SiteVisits from '../tables/cam-operations/SiteVisits';
import LikelyToClose from '../tables/cam-operations/LikelyToClose';
import RacOperations from '../tables/rac-operations/RacOperations';

const Wrapper = styled.section`
  text-align: center;
  padding-bottom: 2em;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 0 auto;
  padding: 1em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.h1`
  padding-top: 1em;
  font-size: 2rem;
`;

const Time = styled.h1`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;
`;

const ScrollDiv = styled.div`
  height: 500px;
  overflow-y: auto;
`

export default class RacOperationsDashboard extends Component {
  state = {
    startDate: thisYearStart(),
    endDate: dayChangeEnd(0),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <DashboardControls>
          <div>
            <Title>RAC Operations</Title>
          </div>
        </DashboardControls>
        <RacOperations user={this.props.user}/>
      </Wrapper>
    );
  }
}
